<mat-nav-list class="m-x--16 m-b--16 m-t-16" *ngIf="!table">
  <ng-template ngFor let-skill [ngForOf]="skills$ | async">
    <mat-divider inset class='divider'></mat-divider>
    <a mat-list-item routerLink=".">
      <mat-icon mat-list-icon>done</mat-icon>
      <div mat-line>
        <h3 class='main'>{{skill.label}}</h3>
      </div>
      <div *ngIf="skill.short_addr.length && showPath" mat-line><span class='sub'>In: {{skill.short_path}}</span></div>
      <div *ngIf="showPath" mat-line>
        <bar-rating [(rate)]="cssRate" [max]="5"></bar-rating>
      </div>
    </a>
  </ng-template>
  <mat-divider inset class='divider'></mat-divider>
</mat-nav-list>



<table mat-table [dataSource]="skills$" class="skill-table" *ngIf="table && !list">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- icon Column -->
  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef > </th>
    <td mat-cell *matCellDef="let element">
      <mat-icon mat-list-icon>done</mat-icon>
    </td>
  </ng-container>

  <!-- label Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Skill </th>
    <td mat-cell *matCellDef="let element">
      <div class="cont">
        <h3 class='main'>{{element.label}}</h3>
        <ng-container *ngIf="showPath">
          <h5 class='path'>{{element.short_path}}</h5>
        </ng-container>
      </div>

    </td>
  </ng-container>

  <!-- path Column >
  <ng-container matColumnDef="weight">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element"> </td>
  </ng-container-->

  <!-- rating Column -->
  <ng-container matColumnDef="rating">
    <th mat-header-cell *matHeaderCellDef style="text-align:center">Self-rating </th>
    <td mat-cell *matCellDef="let element" style="width: 260px">
      <bar-rating (rateChange)="ratefn($event, element.id, element)" [rate]="element?.selfRating" [max]="4"
        [theme]="'horizontal'" [readOnly]="userdata.uid != userid"
        [titles]="['Please rate','Learner', 'Amateur' , 'Advanced', 'Pro']" [showText]="element?.selfRating">
      </bar-rating>
    </td>
  </ng-container>

  <ng-container matColumnDef="rm">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button color="warn" (click)="deleteSkill(element.id)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="endorsments">
    <th mat-header-cell *matHeaderCellDef style="text-align:center">Endorsments </th>
    <td mat-cell *matCellDef="let element">
      <div class="end">
        <mat-chip-list aria-label="Fish selection">
          <mat-chip color="accent" selected>{{element.level?.length ?? 0}}</mat-chip>
        </mat-chip-list>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="endorse_btn">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element">
      <button *ngIf="!element.level?.includes(userdata.uid)" mat-button color="accent"
        (click)="endorsefn(element)">Endorse</button>
      <button *ngIf="element.level?.includes(userdata.uid)" mat-button color="accent"
        (click)="removeEndorse(element)">Remove Endorse</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<ng-container *ngIf="list">
  <ul style="width: 300px;
  word-break: break-all;">
    <ng-container *ngFor="let skill of skills$ | async; let i = index">
      <li *ngIf="i < 3">
        {{skill.label}}
      </li>
    </ng-container>
  </ul>
</ng-container>
