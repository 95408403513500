<div *ngIf="(user$ | async)?.uid as idlogin; else elseBlock">
  <div *ngIf="(user | async)?.uid as profileid">

    <div *ngIf="idlogin === profileid; else elseBlock">

      <mat-card>
        <mat-card-title>Edit Profile</mat-card-title>
        <hr>
        <br>
        <form [formGroup]="reactiveForm" class="form-field-full" *ngIf="profileid" (ngSubmit)="submit()">
          <div fxLayout="row wrap" fxLayoutGap="8px grid">

            <div fxFlex="100" fxFlex.lt-md="100">
              <h2>Profile settings:</h2>
            </div>

            <div fxFlex="50" fxFlex.lt-md="100">
              <mat-form-field>
                <mat-label>Username</mat-label>
                <input matInput formControlName="displayName" required>
                <mat-error *ngIf="reactiveForm.get('displayName')?.invalid">Please enter username
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="50" fxFlex.lt-md="100">
              <mat-form-field>
                <mat-label>Contact email</mat-label>
                <input matInput formControlName="email" readonly required>
                <mat-error *ngIf="reactiveForm.get('email')?.invalid">
                  {{getErrorMessage(reactiveForm)}}</mat-error>
              </mat-form-field>
            </div>





            <!--div fxFlex="50" fxFlex.lt-md="100">
                <mat-form-field>
                <mat-label>City</mat-label>
                <input matInput formControlName="city">
                <mat-error *ngIf="reactiveForm.get('city')?.invalid">Please enter city</mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="50" fxFlex.lt-md="100">
                <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput formControlName="address">
                <mat-error *ngIf="reactiveForm.get('address')?.invalid">Please enter address</mat-error>
                </mat-form-field>
            </div-->

            <div fxFlex="100" fxFlex.lt-md="100">


            </div>

            <!-- <div fxFlex="100" fxFlex.lt-md="100">

                <h2>Optional info:</h2>

            </div>

            <div fxFlex="50" fxFlex.lt-md="100">
                <mat-form-field>
                <mat-label>Company</mat-label>
                <input matInput formControlName="company">
                <mat-error *ngIf="reactiveForm.get('company')?.invalid">Please enter company</mat-error>
                </mat-form-field>
            </div>

            <div fxFlex="50" fxFlex.lt-md="100">
                <mat-form-field>
                <mat-label>Mobile</mat-label>
                <input matInput type="number" formControlName="mobile">
                <mat-icon matSuffix>smartphone</mat-icon>
                <mat-error *ngIf="reactiveForm.get('mobile')?.invalid">Please enter mobile</mat-error>
                </mat-form-field>
            </div> -->

            <!--div fxFlex="50" fxFlex.lt-md="100">
                <mat-form-field>
                <mat-label>Tele</mat-label>
                <input matInput type="number" formControlName="tele">
                <mat-icon matSuffix>phone</mat-icon>
                <mat-error *ngIf="reactiveForm.get('tele')?.invalid">Please enter tele</mat-error>
                </mat-form-field>
            </div-->

            <!-- <div fxFlex="50" fxFlex.lt-md="100">
                <mat-form-field>
                <mat-label>Website</mat-label>
                <input matInput formControlName="website">
                <mat-error *ngIf="reactiveForm.get('website')?.invalid">Please enter website</mat-error>
                </mat-form-field>
            </div> -->

            <!-- <div fxFlex="50" fxFlex.lt-md="100">
                <mat-form-field>
                    <mat-label>Birthday</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="date">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-error *ngIf="reactiveForm.get('date')?.invalid">Please enter </mat-error>
                </mat-form-field>
            </div> -->

            <!--div fxFlex="50" fxFlex.lt-md="100">
                <mat-form-field>
                <mat-label>LinkedIN</mat-label>
                <input matInput formControlName="linkedin">
                <mat-error *ngIf="reactiveForm.get('linkedin')?.invalid">Please enter linkedin</mat-error>
                </mat-form-field>
            </div-->

            <!-- <div fxFlex="50" fxFlex.lt-md="100">
                <mat-form-field>
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender">
                    <mat-option>-- None --</mat-option>
                    <mat-option value="1">male</mat-option>
                    <mat-option value="2">female</mat-option>
                    <mat-option value="2">other</mat-option>
                </mat-select>
                <mat-error *ngIf="reactiveForm.get('gender')?.invalid">Please select gender</mat-error>
                </mat-form-field>
            </div> -->

          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button color="primary" class="m-t-8">Save</button>
          </div>
        </form>

      </mat-card>

      <mat-card>
        <mat-card-subtitle>Change profile picture (max 1MB)</mat-card-subtitle>
        <mat-card-content>
          <button color="primary" mat-stroked-button (click)="upload.click()">
            <mat-icon>upload</mat-icon> Upload picture
          </button>
          <input type="file" class="d-none" #upload (change)="uploadAttachment($event)">
        </mat-card-content>
      </mat-card>


      <mat-card *ngIf='true'>
        <mat-card-title>Edit location</mat-card-title>
        <p>
          Your current location:
          {{(user | async)?.geoaddr}}
        </p>
        <!--button mat-raised-button color="primary" class="m-t-8" (click)="locedit = true">Chenge location</button-->
        <pick-location></pick-location>

      </mat-card>

      <mat-card *ngIf='!addactive'>
        <mat-card-title>Edit Skills</mat-card-title>

        <h3>Your skills:</h3>
        <skill-list-widget [id$]="id$" [displayedColumns]="['icon', 'name', 'rating', 'rm']" [ratereadonly]="false">
        </skill-list-widget>
        <!--skill-list-widget [userid]="(user$ | async)?.uid" [displayedColumns]="['icon', 'name', 'rating', 'rm']" [ratereadonly]="false"></skill-list-widget-->
        <br>
        <button mat-raised-button color="primary" class="m-t-8" (click)="addactive = true">Add skills</button>

      </mat-card>

      <mat-card *ngIf='!addactive'>
        <mat-card-title>Remove profile</mat-card-title>

       <br>

        <div *ngIf='(user | async)?.remove_req else elseBlock'>

          <p>
            Account removal in process.
            <br><br>
            Your request has been recorded and will be processed within 5 working days. 
            <br>
            We will get in touch if there is any action we need you to complete before we can delete your data. 
            <br>
            Please contact becovalent@gmail.com for any queries.
            <br>
            Thank you!
          </p>

          <button *ngIf='!rmprof && !rmprofw' mat-raised-button color="warn" class="m-t-8" (click)="reinstate()">Cancel account removal</button>


        </div>

        <ng-template #elseBlock>

          <p *ngIf='rmprof'>
            Your request has been recorded and will be processed within 5 working days. 
            We will get in touch if there is any action we need you to complete before we can delete your data. 
            Thank you!
          </p>

          <p *ngIf='rmprofw'>
            Do you want to delete your account? This action cannot be undone.
            <br>
            Your personal details will be removed from our database; the messages sent to other Users in Chats, Projects, and News will remain visible.
          </p>

          <button *ngIf='rmprofw' mat-raised-button color="warn" class="m-t-8" (click)="rmprofw = false; rmprof = true; userrmreq();">Yes, remove profile</button>
          <button *ngIf='!rmprof && !rmprofw' mat-raised-button color="warn" class="m-t-8" (click)="rmprofw = true">Remove profile</button>

      </ng-template>

      </mat-card>

      <mat-card *ngIf='addactive'>
        <mat-card-title>Add skills:</mat-card-title>
        <skill-select-widgit (selections)="skill_ids = $event" [multilevel]="false"></skill-select-widgit>
        <div class="d-flex-align-items-center">
          <button mat-raised-button color="primary" class="m-t-8" (click)="addSelectedSkills()">
            Add selected skills
          </button>
          <button (click)="suggestNewSkill()" mat-raised-button color="accent" class="ms-2 m-t-8">Suggest new
            skill
          </button>
        </div>
      </mat-card>


    </div>



    <ng-template #elseBlock>
      <mat-card>
        <mat-card-title>Not authorised!</mat-card-title>
        <hr>
        <img src="https://assets.codepen.io/1600105/internal/screenshots/pens/ZMqKwb.default.png" alt="403"
          class="i403">
        <hr>
      </mat-card>
    </ng-template>

  </div>

  <ng-template #elseBlock>
    <br>
    <p>Loading your profile....</p>

  </ng-template>

</div>

<ng-template #elseBlock>
  <br>
  <p style="padding-left: 20px;">Loading your profile...</p>

</ng-template>
