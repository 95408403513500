import { Injectable,EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertComponent } from '../shared/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  ee: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private dialog: MatDialog) { }

  confirm(title: string, message: string = '', acceptButton: string = 'Yes', rejectButton: string = 'No') {
    return new Promise((resolve, reject) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.disableClose = true;
      dialogConfig.width = '40%'
      dialogConfig.hasBackdrop = true;
      dialogConfig.data = { title, message, type: 'confirm', button: { acceptButton, rejectButton } }
      const dialog = this.dialog.open(AlertComponent, dialogConfig);
      dialog.afterClosed().subscribe(res => {
        resolve(res.status);
      })
    })
  }

  updateCommentEvent(){
    this.ee.emit(true);
  }
}
