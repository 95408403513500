import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Pipe({
  name: 'FilterBonds'
})

export class BondsPipe implements PipeTransform {

  constructor(public afs: AngularFirestore) { }

  transform(value: any, ...args: any[]) {

  }
}
