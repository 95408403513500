import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { UserService } from 'src/app/service/user.service';
import { AlertService } from 'src/app/service/alert.service';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {

  commentForm!: UntypedFormGroup;
  @Input() postId: any;
  @Input() userId: any;
  userName: any;

  constructor(
    private fb: UntypedFormBuilder,
    private firestore: AngularFirestore,
    private userService: UserService,
    private alertService: AlertService
    ) { }

  ngOnInit(): void {
    this.getUserDetails();
    this.commentForm = this.fb.group({
      comment: ['', Validators.required],
      commentedBy: [this.userId],
      postId: [this.postId]
    })
  }

  addComment() {
   
    if (this.commentForm.invalid) {
      return;
    }
    console.log('this.commentForm.value::::',this.commentForm.value)
    const commentsCollection = this.firestore.collection('comments');
    this.commentForm.value['userName']=this.userName;
    commentsCollection.add(this.commentForm.value).catch(err => {
      console.error('Error while adding comment => ', err);
    })

    // this.commentForm.setValue({commentedBy:''});
    this.alertService.updateCommentEvent()
    setTimeout(() => {
      this.commentForm.patchValue({comment:''})
    }, 500);
  }

  getUserDetails(){
    this.userService.userById(this.userId).then(res => {
        if(res){
          this.userName = res['displayName'];
        }
    });
  }
}
