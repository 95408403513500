import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ProjectService } from './project.service';
import { UserService } from './user.service';

export class FileUpload {
  key!: string;
  name!: string;
  url!: string;
  file: File;
  constructor(file: File) {
    this.file = file;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private basePath = '/uploads';
  userdata: any;

  constructor(
    private storage: AngularFireStorage, 
    private afs: AngularFirestore, 
    private userService: UserService, 
    private projectService: ProjectService
  ) {
    this.userService.user$.subscribe(data => {
      this.userdata = data;
    });
  }

  pushFileToStorage(fileUpload: FileUpload, collection: string = '', extra: Object = {}): Observable<number | undefined> {
    const filePath = `${this.basePath}/${fileUpload.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.url = downloadURL;
          fileUpload.name = fileUpload.file.name;
          switch (collection) {
            case 'posts':
              this.savePost(fileUpload);
              break;
            case 'messages':
              this.saveMessage(fileUpload, extra);
              break;
            case 'public-posts':
              this.updateAttachmentInPost(fileUpload, extra);
              break;
            case 'profile-pic':
                this.updateProfilePic(fileUpload);
              break;
            default:
              break;
          }
        });
      })
    ).subscribe();
    return uploadTask.percentageChanges();
  }

  private updateProfilePic(fileUpload: FileUpload) {

    this.afs.collection('users').doc(this.userdata.uid).update({
      photoURL: fileUpload.url
    })

  }

  private saveMessage(fileUpload: FileUpload, extra: any): void {
    let messageObject = {
      dateSent: new Date(),
      fromId: this.userdata.uid,
      message: fileUpload.url,
      fileName: fileUpload.name,
      type: 2
    }
    this.projectService.sendMessage(messageObject, extra.projectId).then().catch(err => {
      console.log('Error while send message => ', err);
    })
  }

  private savePost(fileUpload: FileUpload): void {
    const d = new Date();
    this.afs.collection('posts').add({
      title: this.userdata.displayName + '\'s post',
      avatar: this.userdata.photoURL,
      body: fileUpload.url,
      type: 'attachment',
      creator: this.userdata.uid,
      subtitle: 'Created on ' + d,
      time: Date.now()
    })
  }

  // getFiles(numberItems: number): AngularFireList<FileUpload> {
  //   return this.db.list(this.basePath, ref =>
  //     ref.limitToLast(numberItems));
  // }

  // deleteFile(fileUpload: FileUpload): void {
  //   this.deleteFileDatabase(fileUpload.key)
  //     .then(() => {
  //       this.deleteFileStorage(fileUpload.name);
  //     })
  //     .catch(error => console.log(error));
  // }

  // private deleteFileDatabase(key: string): Promise<void> {
  //   return this.db.list(this.basePath).remove(key);
  // }

  updateAttachmentInPost(fileUpload: FileUpload, extra: any): void {
    this.afs.collection('posts').doc(extra.id).update({
      attachment: fileUpload.url,
      attachmentName: fileUpload.name
    })
  }

  private deleteFileStorage(name: string): void {
    const storageRef = this.storage.ref(this.basePath);
    storageRef.child(name).delete();
  }
}
