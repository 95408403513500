import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/service/alert.service';
import { NotificationService } from 'src/app/service/notification.service';
import { ProjectService } from 'src/app/service/project.service';
import { UserService } from 'src/app/service/user.service';
import { CreateProjectComponent } from 'src/app/shared/project/create-project/create-project.component';
import { ShareProjectComponent } from 'src/app/shared/project/share-project/share-project.component';

@Component({
  selector: 'app-view-project',
  templateUrl: './view-project.component.html',
  styleUrls: ['./view-project.component.scss']
})
export class ViewProjectComponent implements OnInit, OnDestroy {

  projectId!: string;
  team: Array<any> = [];
  project: any;
  skills: Array<any> = [];
  loading: any = {
    skills: true,
    team: true
  };
  subscribe!: Subscription;
  userdata: any;
  access: boolean = false;
  keywords = new Set<string>();
  keywordsLoading: boolean = false;
  invited: boolean = false;
  notificationId: string = '';
  deleteLoading: boolean = false;
  leaveButtonLoading: boolean = false;
  users: any;

  constructor(
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private alert: AlertService,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private notification: NotificationService,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    this.subscribe = this.userService.user$.subscribe(data => {
      this.userdata = data;
      this.getUsers()
      this.projectId = this.activatedRoute.snapshot.paramMap.get('id') || '';
      if (this.projectId) {
        this.subscribe = this.projectService.getProject(this.projectId).subscribe(doc => {
          const data: any = doc.payload.data()
          this.userService.userById(data?.creator).then(res => {
            data.user = res;
            const id = doc.payload.id;
            this.project = { id, ...(data as {}) }
            this.getSkills()
            if (this.project.team.find((t: any) => (t.role == 'admin' || t.role == 'team') && t.id == this.userdata.uid)) {
              this.access = true;
            }
            this.project?.keywords?.forEach((keyword: string) => {
              this.keywords.add(keyword);
            })
          })
        })
      }
    });

    this.activatedRoute.queryParams.subscribe((res) => {
      this.checkNotificationKey()
    })

  }

  checkNotificationKey() {
    this.notificationId = this.activatedRoute.snapshot.queryParamMap.get('key') ?? '';
    if (!this.notificationId) {
      return
    }
    this.notification.getNotificationById(this.notificationId).subscribe(snapshot => {
      const data: any = snapshot.data();
      switch (data.type.toLowerCase()) {
        case 'requestaccess':
          this.acceptOrRejectAccess()
          break;
        case 'invite':
          this.invited = true;
          break;
        default:
          //
          break;
      }
    })
  }

  getUsers() {
    this.afs.collection('users').valueChanges().subscribe(users => {
      this.users = users.filter((user: any) => {
        return user?.uid != this.userdata?.uid
      });
    });
  }

  acceptOrRejectAccess(): void {
    this.alert.confirm('Do you want to give access to the user?', 'You can remove access anytime you want.').then(res => {
      if (res) {
        const user = this.activatedRoute.snapshot.queryParamMap.get('f') as string;
        this.project.team.push({
          id: user,
          role: 'team'
        });
        let sendTo = this.project.team.map((team: any) => {
          if (team.id != user) {
            return team.id
          } else {
            return null;
          }
        })
        this.afs.collection('projects').doc(this.projectId).update({
          team: this.project.team
        }).then(() => {
          this.userService.userById(user).then(res => {
            sendTo = sendTo.filter((team: any) => {
              return team != null;
            })
            this.notification.sendNotification({
              status: 0,
              message: '',
              // title: `${res.displayName} has joined the project`,
              title: ` has joined the project`,
              date: new Date(),
              from: this.userdata.uid,
              pageUrl: `/project/${this.project.id}`,
              to: sendTo,
              action: false,
            })

            this.snackBar.open('Request approved successfully', 'Got it', {
              duration: 5000
            })
          })
        })
      }
      this.router.navigateByUrl(`project/${this.projectId}`)
    })
  }

  getTeamMembers(): void {
    const teamId = this.project.team.map((team: any) => {
      return team.id
    })
    this.subscribe = this.afs.collection('users', ref => ref.where('uid', 'in', teamId).limit(10)).get().subscribe(snapshot => {
      const team = snapshot.docs.map(doc => {
        const id = doc.id;
        const data: any = doc.data();
        const team = this.project.team.find((t: any) => t.id == data.uid);
        data.role = team.role;
        return { id, ...(data as {}) }
      })
      this.team = team;
      this.loading.team = false;
    })
  }

  getSkills(): void {
    if (!this.project.skills?.length) {
      this.loading.skills = false;
      return;
    }
    this.subscribe = this.afs.collection('skill').get().subscribe(snapshot => {
      const skills = snapshot.docs.map(doc => doc.data());
      this.skills = skills.filter((s: any) => this.project.skills.includes(s.id))
      this.loading.skills = false
    })
  }

  removeMember(teamId: string): void {
    this.alert.confirm('Do you want to remove this member?', 'You can add this member later if you want.').then((res) => {
      if (res) {
        this.project.team = this.project.team.filter((team: any) => {
          return team.id != teamId
        })
        this.afs.collection('projects').doc(this.projectId).update({
          team: this.project.team
        });
        this.snackBar.open('Team member removed successfully.', 'Got it', {
          duration: 5000
        })
      }
    })
  }

  editProject(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.data = { user: this.userdata, users: this.users, projectId: this.projectId }
    this.dialog.open(CreateProjectComponent, dialogConfig);
  }


  makeAdmin(userId: string) {
    this.project.team = this.project.team.map((team: any) => {
      return (team.id == userId) ? { id: userId, role: 'admin' } : team
    })
    this.afs.collection('projects').doc(this.projectId).update({
      team: this.project.team
    }).then(() => {
      this.snackBar.open('Team updated successfully', 'Got it', {
        duration: 5000
      })
    })
  }

  leaveProject(): void {
    const isAdminExist = this.project.team.filter((t: any) => (t.role == 'admin' && t.id != this.userdata.uid));
    if (isAdminExist.length) {
      this.leaveButtonLoading = true;
      const team = this.project.team.filter((t: any) => {
        return t.id != this.userdata.uid;
      })
      this.afs.collection('projects').doc(this.projectId).update({
        team
      }).then(() => {
        this.snackBar.open('You successfully leave the project.', 'Got it', {
          duration: 15000
        });
        this.router.navigate([`/user/${this.userdata?.uid}/view`]);
      }).catch(err => {
        this.leaveButtonLoading = true;
      })
    } else {
      this.snackBar.open('Please nominate another user as Admin Role before leave.', 'Got it', {
        duration: 15000
      });
    }
  }

  requestAccess() {
    if (this.invited) {
      const user = this.activatedRoute.snapshot.queryParamMap.get('t') as string;
      this.project.team.push({
        id: user,
        role: 'team'
      })
      let sendTo = this.project.team.map((team: any) => {
        return (team.id != user) ? team.id : null
      })

      this.afs.collection('projects').doc(this.projectId).update({
        team: this.project.team
      }).then(res => {
        this.snackBar.open('Team member added successfully.', 'Got it', {
          duration: 5000,
        })
        this.userService.userById(user).then(res => {
          sendTo = sendTo.filter((team: any) => {
            return team != null;
          })
          this.notification.sendNotification({
            status: 0,
            message: '',
            title: ` has joined the project`,
            // title: `${res.displayName} has joined the project`,
            date: new Date(),
            from: this.userdata.uid,
            pageUrl: `/project/${this.project.id}`,
            to: sendTo,
            action: false,
          })

          this.snackBar.open('Request approved successfully', 'Got it', {
            duration: 5000
          })
        })
      });
    } else {
      this.notification.sendNotification({
        status: 0,
        message: 'Wants to access',
        title: this.project.title,
        date: new Date(),
        from: this.userdata.uid,
        pageUrl: `/project/${this.project.id}`,
        to: [this.project.creator],
        action: true,
        type: 'requestAccess'
      }).then(res => {
        this.snackBar.open('Admin of this project recieved your request. Wait for the admin confirmation', 'Got it', {
          duration: 15000
        })
      })
    }
  }

  addKeywordFromInput(event: MatChipInputEvent): void {
    if (event.value) {
      this.keywords.add(event.value);
      event.input.value = '';
      this.updateKeywords()
    }
  }

  updateKeywords() {
    this.afs.collection('projects').doc(this.projectId).update({ keywords: Array.from(this.keywords) })
  }

  shareProject(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '40%';
    dialogConfig.data = { project: this.project, userId: this.project.creator }
    this.dialog.open(ShareProjectComponent, dialogConfig);
  }

  removeKeyword(keyword: string) {
    this.keywords.delete(keyword);
    this.updateKeywords()
  }

  declineProject() {
    this.notification.deleteNotification(this.notificationId).then(res => {
      this.router.navigate(['/'])
    });
  }

  deleteProject(): void {
    this.deleteLoading = true
    this.alert.confirm('Do you want to delete this project?').then(res => {
      if (res) {
        // this.projectService.deleteProject(this.projectId).then(res => {
        //   this.snackBar.open('Project deleted successfully');
        //   this.router.navigate(['/']);
        // });

        this.notification.sendNotification({
          status: 0,
          message: '',
          title: 'Project has been deleted',
          date: new Date(),
          from: this.userdata.uid,
          pageUrl: '',
          to: this.filterIdFromTeam(this.project.team),
          action: false,
          type: 'projectDelete'
        })
      }
      this.deleteLoading = false;
    })
  }

  filterIdFromTeam(team: Array<any>) {
    let t = team.map((t => {
      return t.id
    }))
    return t;
  }

  getUserRole() {
    const role = this.project.team.find((t: any) => (t.role == 'admin' && t.id == this.userdata.uid));
    return (role) ? 'admin' : 'team';
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }
}
