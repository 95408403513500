<div *ngIf="user$ | async as user; else showLogin" class="d-flex align-items-center">


  <!--button mat-fab color="accent" aria-label="My profile menu">
        <img class="matero-avatar" src="{{ user.photoURL }}" width="56" alt="avatar" />
    <-->
  <div class="me-3">
    <small>{{user.displayName}}</small>
  </div>

  <button mat-fab class="matero-toolbar-button matero-avatar-button" href="javascript:void(0)"
    [matMenuTriggerFor]="menu">
    <img class="avatar" src="{{ user.photoURL }}" alt="avatar" />
    <!--span class="matero-username" fxHide.lt-sm>{{ user.displayName }}</span-->
  </button>

  <mat-menu #menu="matMenu">
    <button routerLink="/user/{{user.uid}}/view" mat-menu-item>
      <mat-icon>account_circle</mat-icon>
      <span>View profile</span>
    </button>
    <button routerLink="/user/{{user.uid}}/edit" mat-menu-item>
      <mat-icon>settings</mat-icon>
      <span>Edit profile</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>



</div>
<ng-template #showLogin>
</ng-template>
