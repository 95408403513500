import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name:'dateFormat'
})

export class DateFormatPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return moment(value.seconds*1000).startOf('m').fromNow()
  }
}
