import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { ViewChild } from '@angular/core';

import { DemoAdapter } from './adapter'
import { GroupAdapter } from './adapter-group'
import { IChatController, User } from 'ng-chat';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { UserService } from '../../service/user.service';
import { BondsService } from 'src/app/service/bonds.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements AfterViewInit {

  @ViewChild('userChat') protected ngChatInstance!: IChatController;

  title = 'app';

  public adapter: any;
  public groupAdapter: any;
  users: Observable<any[]>;
  msg: AngularFirestoreCollection<any>;
  usr: AngularFirestoreCollection<any>;
  resolvedId: string | undefined;
  groups: boolean = false;

  user$ = this.userService.user$;
  userId$ = this.userService.userId$;
  collapsed: boolean = true;
  inbox = [];


  constructor(
    private firestore: AngularFirestore,
    public userService: UserService,
    private bondsService: BondsService
  ) {
    this.users = firestore.collection('users').valueChanges();
    this.msg = firestore.collection('messages');
    this.usr = firestore.collection('users');

    this.userId$.subscribe((uid: any) => {
      this.resolvedId = uid;
    })

    this.userId$.pipe(
      switchMap((uid: any) => {
        console.log('inbox id: ', uid);
        return this.usr.doc(uid).collection(
          'inbox',
          ref => ref.orderBy('dateSent', 'desc')
        ).valueChanges();
      })
    ).subscribe((msgArray: any) => {
      console.log("inbox: ", msgArray)
      this.inbox = msgArray;
    });

    // this.groupAdapter = new GroupAdapter(this.users, this.userId$, this.msg, this.usr);

    this.userId$.subscribe((uid: any) => {
      this.bondsService.listActiveBonds(uid).subscribe((snapshot) => {
        let ids: Array<any> = [];
        snapshot.map(e => {
          const data = e.payload.doc.data();
          ids.push(data.uid)
        })
        this.adapter = new DemoAdapter(this.getContentById(ids), this.userId$, this.msg, this.usr);
      })
    })

  }


  getContentById(ids: string[]): Observable<any> {
    if (ids.length) {
      let observables: Observable<any[]>[] = [];
      while (ids.length) {
        let observable = this.firestore.collection('users', ref => ref.where('uid', 'in', ids.splice(0, 10))).get().pipe(map(e => e.docs.map(user => user.data())))
        observables.push(observable);
      }
      return combineLatest(observables).pipe(map(users => users.flat(1)))
    }
    return of([]);
  }


  ngAfterViewInit() {
    this.userService.chatTrigger.subscribe(res => {
      //console.log(res)
      this.ngChatInstance?.triggerOpenChatWindow(res as User)
    })
  }

  messageSeen(msg: any) {

    if (this.inbox) {
      if (this.inbox.length) {
        msg.map((message: any) => {

          if (this.inbox.find((x: any) => x.dateSent == message.dateSent)) {
            this.usr.doc(this.resolvedId).collection('inbox').doc(message.dateSent).delete();

            let conversationId: string;
            if (message.toId === this.resolvedId) {
              conversationId = conversationId = [message.toId, message.fromId].sort().join('-');
              this.usr.doc(this.resolvedId).collection('inbox').doc('counters').set({
                [message.fromId]: 0
              }, { merge: true });
            } else {
              conversationId = message.toId;
              this.usr.doc(this.resolvedId).collection('inbox').doc('counters').set({
                [message.toId]: 0
              }, { merge: true });
            }
            this.msg.doc(conversationId).collection('conv').doc(message.dateSent).update(Object.assign({}, message));
          }
        })
      }
    }
  }

  onChangeGroup(event: any) {
    console.log(event);
    this.groups = !this.groups;
  }

}
