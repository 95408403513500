<div class="d-flex align-items-center justify-content-center">
  <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
</div>

<div class="d-flex justify-content-between" *ngIf="!loading">
  <div class="drag-drop-container">
    <mat-card>
      <div class="d-flex justify-content-between align-items-center">
        <strong>To do</strong>
        <button (click)="addNoteTodo = !addNoteTodo" matTooltip="Add a note to this column" matTooltipPosition="before"
          mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <mat-card-content>
        <div class="row pb-4" *ngIf="addNoteTodo">
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
              <textarea matInput [(ngModel)]="note" placeholder="Enter a note"></textarea>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <button mat-raised-button class="w-100" (click)="addNote('todo')" color="accent">Add</button>
          </div>
          <div class="col-sm-6">
            <button mat-raised-button class="w-100" (click)="addNoteTodo = !addNoteTodo">Cancel</button>
          </div>
        </div>

        <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo"
          [cdkDropListConnectedTo]="[progressList,doneList]" class="drag-drop-list" (cdkDropListDropped)="drop($event)">
          <div class="drag-drop-box" *ngFor="let item of todo; let i = index" cdkDrag>
            <div class="d-flex justify-content-between w-100">
              <span class="content">
                {{item}}
              </span>
              <button mat-icon-button class="more_horiz" [matMenuTriggerFor]="more_options">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #more_options="matMenu">
                <button mat-menu-item (click)="removeItem('todo', i)">Remove</button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="drag-drop-container">
    <mat-card>
      <div class="d-flex justify-content-between align-items-center">
        <strong>In Progress</strong>
        <button (click)="addNoteProgress = !addNoteProgress" matTooltip="Add a note to this column"
          matTooltipPosition="before" mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <mat-card-content>
        <div class="row pb-4" *ngIf="addNoteProgress">
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
              <textarea matInput [(ngModel)]="note" placeholder="Enter a note"></textarea>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <button mat-raised-button class="w-100" (click)="addNote('progress')" color="accent">Add</button>
          </div>
          <div class="col-sm-6">
            <button mat-raised-button class="w-100" (click)="addNoteProgress = !addNoteProgress">Cancel</button>
          </div>
        </div>
        <div cdkDropList #progressList="cdkDropList" [cdkDropListData]="progress"
          [cdkDropListConnectedTo]="[todoList,doneList]" class="drag-drop-list" (cdkDropListDropped)="drop($event)">
          <div class="drag-drop-box" *ngFor="let item of progress; let i = index" cdkDrag>
            <div class="d-flex justify-content-between w-100">
              <span class="content">
                {{item}}
              </span>
              <button mat-icon-button class="more_horiz" [matMenuTriggerFor]="more_options">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #more_options="matMenu">
                <button mat-menu-item (click)="removeItem('progress', i)">Remove</button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="drag-drop-container">
    <mat-card>
      <div class="d-flex justify-content-between align-items-center">
        <strong>Done</strong>
        <button (click)="addNoteDone = !addNoteDone" matTooltip="Add a note to this column" matTooltipPosition="before"
          mat-icon-button>
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <mat-card-content>
        <div class="row pb-4" *ngIf="addNoteDone">
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
              <textarea matInput [(ngModel)]="note" placeholder="Enter a note"></textarea>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <button mat-raised-button class="w-100" (click)="addNote('done')" color="accent">Add</button>
          </div>
          <div class="col-sm-6">
            <button mat-raised-button class="w-100" (click)="addNoteDone = !addNoteDone">Cancel</button>
          </div>
        </div>
        <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="done"
          [cdkDropListConnectedTo]="[todoList,progressList]" class="drag-drop-list" (cdkDropListDropped)="drop($event)">
          <div class="drag-drop-box" *ngFor="let item of done; let i = index" cdkDrag>
            <div class="d-flex justify-content-between w-100">
              <span class="content">
                {{item}}
              </span>
              <button mat-icon-button class="more_horiz" [matMenuTriggerFor]="more_options">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #more_options="matMenu">
                <button mat-menu-item (click)="removeItem('done', i)">Remove</button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div *ngIf="changesDetected">
  <button mat-raised-button color="accent" (click)="setNotes()">Save</button> &nbsp;
  <button mat-raised-button (click)="reset()">Cancel</button>
</div>
