import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { BondsService } from 'src/app/service/bonds.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'bond-show-widget',
  templateUrl: './bond-show-widget.component.html',
  styleUrls: ['./bond-show-widget.component.scss']
})
export class BondShowWidgetComponent implements OnInit {

  @Input() userId: any;
  @Output() pendingBonds = new EventEmitter();
  bonds: Array<any> = [];
  userdata: any;

  constructor(
    private userService: UserService,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar,
    private bondsService: BondsService,
    private alertService: AlertService,
    private router: Router
  ) { }


  ngOnInit(): void {
    this.userService.user$.subscribe(data => {
      this.userdata = data;
      this.getBondRequests();
    });
  }

  ngOnChanges() {
    this.getBondRequests();
  }

  viewProfile(id: string) {
    this.router.navigate([`user/${id}/view`]);
  }

  getBondRequests() {
    const users: any = {};
    this.afs.collection('users').get().subscribe((snapshot) => {
      snapshot.forEach((doc) => {
        users[doc.id] = doc.data();
      })
      this.bondsService.listBonds(this.userId).subscribe((snapshot) => {
        this.bonds = snapshot.map(e => {
          const data = e.payload.doc.data();
          const id = e.payload.doc.id;
          data.username = users[data.uid]?.displayName;
          data.photoUrl = users[data.uid]?.photoURL;
          return { id, ...(data as {}) }
        })
      })
    })

  }

  updateBondStatus(bond: any, status: boolean): void {
    const bondIdFirstUser = bond.id;
    const bondIdSecondUser = bond.docId;
    if (status == true) {
      this.bondsService.approveRequest(bondIdFirstUser, bondIdSecondUser, bond.uid).then(() => {
        this.snackBar.open('Congratulations!, Request accepted successfully', 'Got it', {
          duration: 5000
        })
      })
    } else {
      this.removeBondRequest(bond);
    }
  }

  removeBondRequest(bond: any) {
    const bondIdFirstUser = bond.id;
    const bondIdSecondUser = bond.docId;
    this.alertService.confirm('Confirmation', 'Do you want to remove this User from your Bonds list?').then(res => {
      if (res) {
        this.bondsService.remove(bondIdFirstUser, bondIdSecondUser, bond.uid).then(() => {
          this.snackBar.open('Bond removed successfully', 'Got it', {
            duration: 5000
          });
        })
      }
    })
  }
}
