import { Component, OnInit } from '@angular/core';

import { ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';

import { AbstractControl } from '@angular/forms';
import { map, take, debounceTime, filter, distinctUntilChanged } from 'rxjs/operators';

//import { AgmCoreModule } from '@agm/core';


import { UserService } from '../../service/user.service';
import { GeocodeService, Location } from '../../service/geocode.service';

import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { fromEvent } from 'rxjs';

// import * as firebase from 'firebase/compat/app';

import firebase from 'firebase/compat/app';

import { Router } from '@angular/router';

import * as geofire from 'geofire-common';

@Component({
  selector: 'pick-location',
  templateUrl: './pick-location.component.html',
  styleUrls: ['./pick-location.component.scss'],
  host: {
    '(window:resize)': 'onWindowResize($event)'
  }
})
export class PickLocationComponent implements OnInit {

  isLinear = false;

  betaFormGroup!: UntypedFormGroup;
  nameFormGroup!: UntypedFormGroup;
  locationFormGroup!: UntypedFormGroup;
  skillsFormGroup!: UntypedFormGroup;

  address = 'London';
  location!: Location;
  loading!: boolean;

  skills: Observable<any[]>;
  // selected_skills: Observable<any[]>;
  user!: AngularFirestoreDocument<any>;
  userid!: string;
  displayName!: string;
  email!: string;
  phoneNumber!: string;
  photoURL!: string;

  doc!: AngularFirestoreDocument<any>;
  user$ = this.userService.user$;

  skillIds: Array<string> = [];


  showprogress = false;

  width: number = window.innerWidth;

  @ViewChild('postcodeSearchInput', { static: true }) postcodeSearchInput!: ElementRef;



  constructor(
    private _formBuilder: UntypedFormBuilder,
    private afs: AngularFirestore,
    private geocodeService: GeocodeService,
    private ref: ChangeDetectorRef,
    private userService: UserService,
    private auth: AngularFireAuth,
    private fns: AngularFireFunctions,
    private router: Router,
  ) {
    this.skills = afs.collection('skill').valueChanges();
    // this.selected_skills = afs.collection('skill', ref => ref.where('id', 'array-contains', 'this.userskills.value')).valueChanges();

    this.auth.authState.subscribe((user) => {

      if (user) {
        console.log(user);
        this.userid = user.uid || '';
        this.displayName = user.displayName || '';
        this.email = user.email || '';
        this.phoneNumber = user.phoneNumber || '';
        this.photoURL = user.photoURL || '';
        this.user = this.afs.collection('users').doc(user.uid); // doc(`users/${user.uid}`);
      }

    });

  }

  ngOnInit(): void {
    this.betaFormGroup = this._formBuilder.group({
      bataCtrl: new UntypedFormControl(
        { value: '', disabled: false },
        {
          validators: Validators.required,
          asyncValidators: CustomValidator.betakeyval(this.afs),
          updateOn: 'blur'
        }
      )
    });

    this.nameFormGroup = this._formBuilder.group({
      nameCtrl: new UntypedFormControl(
        { value: '', disabled: false },
        {
          validators: Validators.required,
          asyncValidators: CustomValidator.usernameval(this.afs),
          updateOn: 'blur'
        }
      )
    });
    this.locationFormGroup = this._formBuilder.group({
      locationCtrl: new UntypedFormControl(
        { value: '', disabled: false },
        {
          validators: Validators.required,
          asyncValidators: this.postcodeval2(),
          updateOn: 'blur'
        }
      ),
      mapCtrl: ['']
    });
    this.skillsFormGroup = this._formBuilder.group({
      skillsCtrl: ['', Validators.required]
    });

  }


  get betakey() {
    return this.betaFormGroup.get('bataCtrl');
  }

  get username() {
    return this.nameFormGroup.get('nameCtrl');
  }

  get postcode() {
    return this.locationFormGroup.get('locationCtrl');
  }

  get location_form() {
    return this.locationFormGroup.get('mapCtrl')?.value;
  }

  get userskills() {
    return this.skillsFormGroup.get('skillsCtrl');
  }

  onLogout() {
    console.log('logout');
    this.auth.signOut().then(() => {
      window.location.reload();
    });
  }

  /*
    showLocation() {
      this.addressToCoordinates();
    }


    addressToCoordinates() {
      this.loading = true;
      this.geocodeService.geocodeAddress(this.address)
      .subscribe((location: Location) => {
          this.location = location;
          console.log(location);
          this.loading = false;
          this.ref.detectChanges();
        }
      );
    }

    onPostcodeChange(val) {

    }

    */
  validateForm() {
    // this.locationFormGroup.get('locationCtrl').updateValueAndValidity();
    // this.locationFormGroup.get('locationCtrl').markAsTouched();
    console.log(this.locationFormGroup.get('locationCtrl'));
  }

  postcodeval2() {
    return (control: AbstractControl) => {

      const postcode = control.value;

      console.log('validator control ->', control);

      const geoans = this.geocodeService.geocodeAddress(postcode);
      console.log('this should be some observabe ->', geoans);

      return geoans.pipe(
        // debounceTime(5000),
        map(loc => {
          console.log('We found you: => ', loc);
          // console.log("PF: => ", control.parent.get('mapCtrl') );

          // control.parent.get('mapCtrl').patchValue( loc );
          this.location = loc;
          this.ref.detectChanges();
          // control.parent.get('mapCtrl').value = loc.addr;
          // parentenv.location = loc;
          return loc?.success ? null : { postcodeExists: false };
        }),
      );
    };
  }

  test_progress() {
    this.showprogress = !this.showprogress;
    //  if(this.showprogress == "indeterminate") {
    //    this.progress = "determinate";
    //  } else {
    //    this.progress = "indeterminate";
    //  }

  }

  onWindowResize(event: { target: { innerWidth: number; }; }) {
    this.width = event.target.innerWidth;
  }

  finalise() {

    this.showprogress = true;

    const lat = this.location.lat;
    const lng = this.location.lng;
    const hash = geofire.geohashForLocation([lat, lng]);


    const data = {
      geoaddr: this.location.addr,
      pos: {
        geohash: hash,
        geopoint: new firebase.firestore.GeoPoint(lat, lng)
      }

    };

    console.log(data);
    this.user.set(data, { merge: true });

  }

}

export class CustomValidator {
  static betakeyval(afs: AngularFirestore) {
    return (control: AbstractControl) => {

      const username = control.value;

      return afs.collection('global', ref => ref.where('pubkey', '==', username))

        .valueChanges().pipe(
          debounceTime(500),
          take(1),
          map(arr => arr.length ? null : { rightKey: false }),
        );
    };
  }
  static usernameval(afs: AngularFirestore) {
    return (control: AbstractControl) => {

      const username = control.value; // .toLowerCase();

      return afs.collection('users', ref => ref.where('displayName', '==', username))

        .valueChanges().pipe(
          debounceTime(500),
          take(1),
          map(arr => arr.length ? { usernameAvailable: false } : null),
        );
    };
  }

  static postcodeval(geocodeService: GeocodeService, ref: ChangeDetectorRef) {
    return (control: AbstractControl) => {

      const postcode = control.value;

      console.log('validator control ->', control);

      const geoans = geocodeService.geocodeAddress(postcode);
      console.log('this should be some observabe ->', geoans);

      return geoans.pipe(
        // debounceTime(5000),
        map(loc => {
          console.log('we found: => ', loc);
          console.log('PF: => ', control.parent?.get('mapCtrl'));

          control.parent?.get('mapCtrl')?.patchValue(loc);
          ref.detectChanges();
          // control.parent.get('mapCtrl').value = loc.addr;
          // parentenv.location = loc;
          return loc.success ? null : { postcodeExists: false };
        }),
      );
    };
  }

}

