import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipSelectionChange } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {

  projectForm!: UntypedFormGroup;
  users: any[] = [];
  userdata: any;
  skillIds: Array<string> = [];
  project: any;

  skills: Array<any> = [];
  projectId: string = '';
  loading: boolean = false;
  access: boolean = true;
  title: string = 'Create Project';
  submit: boolean = false;
  filterUser: string = '';

  constructor(
    private firestore: AngularFirestore,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CreateProjectComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private snackBar: MatSnackBar
  ) {

    this.users = data.users.sort((a: any, b: any) => {
      return a.displayName - b.displayName;
    }); // All users
    this.userdata = data.user; // Single logged in user
    this.projectId = data.projectId;
  }

  ngOnInit(): void {
    this.projectForm = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', Validators.required],
      type: ['public'],
      team: [],
      creator: [this.userdata.uid],
      skills: ['']
    })
    this.getSkills()
    if (this.projectId) {
      this.title = 'Edit Project'
      this.getProject()
    }
  }

  getSkills() {
    this.firestore.collection('skill', ref => ref.orderBy('label')).get().subscribe(snapshot => {
      this.skills = snapshot.docs.map((skill) => {
        return skill.data();
      })
    });
  }

  getProject(): void {
    this.loading = true;
    this.firestore.collection('projects').doc(this.projectId).get().subscribe(snapshot => {
      this.project = snapshot.data();
      const team = this.project.team.map((team: any) => {
        return team.id
      })
      this.projectForm.patchValue({
        title: this.project.title,
        description: this.project.description,
        type: this.project.type,
        team,
        skills: this.project?.skills,
      })
      // console.log(this.project)
      if (this.project.creator != this.userdata.uid) {
        this.access = false;
      }
      this.loading = false;
    })
  }

  saveProject() {
    this.submit = true;
    if (this.projectForm.valid) {
      this.projectForm.value.skills = this.skillIds;
      // this.projectForm.value.team = this.sortTeamMembersData();
      this.projectForm.value.team = [{ id: this.userdata.uid, role: 'admin' }];
      if (this.projectId) {
        this.projectForm.value.updatedAt = Date.now();
        this.firestore.collection('projects').doc(this.projectId).update(this.projectForm.value)
        this.projectId = '';
        this.snackBar.open('Project updated successfully', 'Got it', {
          duration: 5000
        })
        this.submit = false;
        this.dialogRef.close();
      } else {
        this.projectForm.value.createdAt = Date.now();
        this.firestore.collection('projects').add(this.projectForm.value).then(() => {
          this.snackBar.open('Project created successfully', 'Got it', {
            duration: 5000
          })
          this.submit = false;
          this.dialogRef.close();
        });
      }
    }
  }

  removeTeam(value: string) {
    const team = this.projectForm.value.team as string[];
    const data = team.filter((t: any) => {
      return t != value
    })
    this.projectForm.patchValue({
      team: data
    })
  }

  filterUsers(event: any) {
    this.filterUser = event.target.value;
  }

  // sortTeamMembersData() {
  //   this.projectForm.value.team.push(this.userdata.uid);
  //   return this.projectForm.value.team.map((team: any) => {
  //     return (this.userdata.uid == team) ? { id: team, role: 'admin' } : { id: team, role: 'team' }
  //   })
  // }
}

