<div class="container-fluid mt-4" *ngIf="project">
  <div class="row">
    <div class="col-sm-6">
      <div class="d-flex" [ngStyle]="{gap: '15px'}">
        <div class="d-flex flex-column">
          <h2>{{project?.title}}</h2>
        </div>
        <div class="menu">
          <button mat-raised-button color="accent" (click)="requestAccess()" *ngIf="!access">Belong</button>&nbsp;
          <button mat-raised-button color="warn" (click)="declineProject()" *ngIf="!access && invited">Decline</button>
          <button mat-raised-button color="accent" (click)="getTeamMembers()" *ngIf="access" [matMenuTriggerFor]="menu">
            <mat-icon>group</mat-icon>
            Team
          </button>
          <mat-menu #menu="matMenu" class="view-project {{getUserRole()}}">
            <table class="table table-responsive table-borderless" *ngIf="team.length">
              <tr *ngFor="let t of team">
                <td [ngStyle]="{width:'40%'}">
                  <h4 class="m-0">
                    <a mat-button routerLink="/user/{{t.id}}/view">
                      {{(t?.displayName.length>10) ? (t?.displayName | slice:0:10)+'...' : t?.displayName}}
                    </a>
                  </h4>
                </td>
                <ng-container *ngIf="t.role == 'admin'">
                  <td colspan="2">
                    <button mat-icon-button>
                      <mat-icon>admin_panel_settings</mat-icon> Admin
                    </button>
                  </td>
                </ng-container>
                <ng-container *ngIf="getUserRole() == 'admin' && t.id != userdata?.uid">
                  <td [ngStyle]="{width:'30%'}">
                    <button class="w-100" color="primary" mat-raised-button (click)="removeMember(t.id)">Remove</button>
                  </td>
                  <td [ngStyle]="{width:'30%'}">
                    <button class="w-100" [disabled]="t.role == 'admin'" color="accent" mat-raised-button
                      (click)="makeAdmin(t.id)">Make Admin</button>
                  </td>
                </ng-container>
                <ng-container *ngIf="getUserRole() == 'team' && t.role != 'admin'">
                  <td colspan="2">
                    <button mat-icon-button>
                      <mat-icon>admin_panel_settings</mat-icon> Member
                    </button>
                  </td>
                </ng-container>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <button color="accent" class="mt-3 w-100" mat-raised-button (click)="shareProject()">
                    <mat-icon>people</mat-icon> Invite People
                  </button>
                </td>
              </tr>
              <mat-divider></mat-divider>
            </table>
            <!-- <ng-container *ngIf="getUserRole() == 'team'">
              <a routerLink="/user/{{t.id}}/view" mat-menu-item *ngFor="let t of team">
                <mat-icon>account_circle</mat-icon>
                <span>{{(t?.displayName.length>10) ? (t?.displayName | slice:0:10)+'...' : t?.displayName}}</span>
              </a>
              <div class="d-flex justify-content-end">
                <button mat-raised-button color="accent" (click)="shareProject()">
                  Invite People
                </button>
              </div>
            </ng-container> -->
            <div class="d-flex justify-content-center align-items-center mt-2" *ngIf="loading.team">
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
          </mat-menu>
          <button mat-raised-button *ngIf="getUserRole() == 'admin'" color="primary" class="ms-2" (click)="editProject()">
            <mat-icon>edit</mat-icon> Edit
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <button *ngIf="access" mat-raised-button color="warn" class="ms-2 float-end" [disabled]="leaveButtonLoading"
        (click)="leaveProject()">{{leaveButtonLoading ? 'Loading...' :'Leave'}}</button>
      <button *ngIf="project.creator == userdata?.uid" [disabled]="deleteLoading" mat-raised-button color="warn"
        class="float-end" (click)="deleteProject()">
        {{deleteLoading ? 'Deleting...' : 'Delete Project'}}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8">
      <mat-card>
        <mat-card-title class="d-flex align-items-center">
          <mat-icon>description</mat-icon>&nbsp;Purpose
        </mat-card-title>
        <mat-card-content>
          <div class="pt-2">
            {{project?.description}}
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="access">
        <mat-card-title><mat-icon>flag</mat-icon> Goals</mat-card-title>
        <app-tasks></app-tasks>
      </mat-card>
      <mat-card *ngIf="access && projectId">
        <mat-card-title class="mb-2">
          <mat-icon>chat</mat-icon> Chat</mat-card-title>
        <app-chatbot [projectId]="projectId" [userId]="userdata?.uid"></app-chatbot>
      </mat-card>
    </div>
    <div class="col-sm-4">
      <mat-card>
        <mat-card-title class="pb-2 d-flex align-items-center">
          <mat-icon>psychology</mat-icon>&nbsp;Skills
        </mat-card-title>
        <mat-card-content class="overflow-auto" [ngStyle]="{maxHeight: '300px'}">
          <div class="d-flex justify-content-center" *ngIf="loading.skills">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>
          <mat-selection-list #shoes [multiple]="false">
            <mat-list-option *ngFor="let skill of skills" [value]="skill.id">
              <mat-icon mat-list-icon>lens_blur</mat-icon>
              {{skill.label}}
            </mat-list-option>
            <mat-list-option class="text-center" *ngIf="!loading.skills && skills.length == 0">
              No skills selected for this project
            </mat-list-option>
          </mat-selection-list>
        </mat-card-content>
      </mat-card>
      <mat-card class="mt-3" *ngIf="access">
        <mat-card-title class="pb-2 d-flex align-items-center">
          <mat-icon>keyboard</mat-icon>&nbsp;Keywords
        </mat-card-title>
        <mat-card-content>
          <mat-form-field class="example-chip-list w-100 mb-3" appearance="fill">
            <mat-label>keywords</mat-label>
            <mat-chip-list #chipList aria-label="keywords" multiple>
              <mat-chip color="accent" *ngFor="let keyword of keywords" [selected]="keyword" [value]="keyword"
                (removed)="removeKeyword(keyword)">
                {{keyword}}
                <button matChipRemove mat-icon-button>
                  <mat-icon style="line-height: 0px;">cancel</mat-icon>
                </button>
              </mat-chip>
              <input placeholder="New keyword..." [matChipInputFor]="chipList"
                (matChipInputTokenEnd)="addKeywordFromInput($event)">
            </mat-chip-list>
            <mat-hint><strong>Note:</strong> Hit ENTER button to add more keywords</mat-hint>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div class="spinner" *ngIf="!project">
  <mat-spinner [diameter]="60"></mat-spinner>
</div>
