<mat-card>
  <mat-tab-group class="m--16" [selectedIndex]="tabIndex" (selectedTabChange)="tabChanged($event)">
    <mat-tab label="Skills">
      <div class="m-16">
        <p class="widget">
          <skill-list-widget [id$]="id$" [displayedColumns]="displayedColumns"></skill-list-widget>
        </p>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span [matBadge]="pendingBonds$" *ngIf="pendingBonds$ > 0" matBadgeColor="accent" matBadgeOverlap="false">Bonds</span>
        <span *ngIf="pendingBonds$ == 0">Bonds</span>
      </ng-template>
      <div class="m-16">
        <p class="widget">
          <bond-show-widget (pendingBonds)="pendingBonds($event)" *ngIf="id" [userId]="id"></bond-show-widget>
        </p>
      </div>
    </mat-tab>
    <mat-tab label="Projects">
      <div class="m-16">
        <h3>This feature is coming soon</h3>
        <!--h3>
          {{ (user | async)?.displayName }}'s projects
        </h3-->
        <!-- <p class="widget">
          No active projects.
        </p> -->
        <!--app-projects [userId]="id"></app-projects-->
      </div>
    </mat-tab>
    <!-- <mat-tab label="Activity">
      <div class="m-16">
        <h3>
          {{ (user | async)?.displayName }}'s activity
        </h3>
        No recent activity.
      </div>
    </mat-tab> -->
  </mat-tab-group>
</mat-card>
