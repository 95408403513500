import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from 'src/app/service/alert.service';
import { BondsService } from 'src/app/service/bonds.service';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-share-project',
  templateUrl: './share-project.component.html',
  styleUrls: ['./share-project.component.scss']
})
export class ShareProjectComponent implements OnInit {

  project: any;
  shareWith: any = '1';
  team: any;
  userId: any;
  sharing: boolean = false;
  filterTeam: string = '';

  projectSharingTeamMembers = new Set();

  constructor(
    private afs: AngularFirestore,
    private dialogRef: MatDialogRef<ShareProjectComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private snackBar: MatSnackBar,
    private notification: NotificationService,
    private bondsService: BondsService,
  ) {
    this.userId = data.userId;
    this.project = data.project
  }

  ngOnInit(): void {
    this.getBonds();
  }

  getBonds(): void {


    const users: any = {};
    this.afs.collection('users').get().subscribe((snapshot) => {
      snapshot.forEach((doc) => {
        users[doc.id] = doc.data();
      })
      this.bondsService.listBonds(this.userId).subscribe((snapshot) => {
        this.team = snapshot.map(e => {
          const data = e.payload.doc.data();
          const id = e.payload.doc.id;
          data.username = users[data.uid]?.displayName;
          data.photoUrl = users[data.uid]?.photoURL;
          return { id, ...(data as {}) }
        })
      })
    })
  }

  checkboxChange($event: MatCheckboxChange) {
    this.projectSharingTeamMembers.add($event.source.value);
  }

  shareProject() {
    this.sharing = true;
    const teamMembers = Array.from(this.projectSharingTeamMembers);
    if (teamMembers.length > 0) {
      this.notification.sendNotification({
        status: 0,
        title: this.project.title,
        date: new Date(),
        pageUrl: `/project/${this.project.id}`,
        action: true,
        type: 'invite',
        message: 'shared a Project',
        from: this.userId,
        to: teamMembers
      }).then(res => {
        this.snackBar.open('Project is shared with the selected members', 'Got it', {
          duration: 5000
        })
        this.sharing = false;
        this.dialogRef.close()
      })
    }
  }

  // filter(event: any) {
  //   if (event.target.value) {
  //     this.team = this.team.map((t: any) => {
  //       console.log(event.target.value.toLowerCase())
  //       if (t.fromUsername.toLowerCase().includes(event.target.value.toLowerCase())) {
  //         t.matched = true
  //       } else {
  //         t.matched = false;
  //       }
  //       return t;
  //     });
  //     console.log(this.team)
  //   } else {
  //     this.getBonds();
  //   }
  // }
}
