import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';


import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { Observable, of, Subject, BehaviorSubject } from 'rxjs';

import { UserService } from '../../service/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SuggestNewSkillComponent } from 'src/app/shared/suggest-new-skill/suggest-new-skill.component';

import { FileUpload } from 'src/app/model/file-upload';
import { UploadService } from 'src/app/service/upload.service';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  reactiveForm!: UntypedFormGroup;

  public id: string = "";
  public id$: Subject<string> = new BehaviorSubject<string>('');
  public doc!: AngularFirestoreDocument<any>;
  public user!: Observable<any>;

  user$ = this.userService.user$;

  public skillIds: any;
  public skill_ids: any;

  public addactive = false;
  public locedit = false;
  public rmprof = false;
  public rmprofw = false;


  selectedFiles?: FileList;
  currentFileUpload?: FileUpload;
  percentage: number = 0;





  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private fb: UntypedFormBuilder,
    public userService: UserService,
    private matDialog: MatDialog,
    private uploadService: UploadService,
  ) {
    
    this.reactiveForm = this.fb.group({
      displayName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      gender: ['', []],
      city: ['', []],
      address: ['', []],
      company: ['', []],
      mobile: ['', []],
      tele: ['', []],
      website: ['', []],
      date: ['', []],
      uid: ['', []],
      geo: ['', []],
      skills: ['', []],
      linkedin: ['', []],
    });

  }

  ngOnInit() {
    this?.route?.parent?.paramMap.subscribe(params => {
      this.id = params.get('id') || '';
      this.id$.next(params.get('id') || '');
      // console.log(this.id);

      this.doc = this.afs.collection('users').doc(this.id);
      this.user = this.doc.valueChanges();

      this.user.subscribe(data => {
        this.reactiveForm.patchValue(data);
      });

    });
  }

  getErrorMessage(form: UntypedFormGroup) {
    return form.get('email')?.hasError('required')
      ? 'You must enter a value'
      : form.get('email')?.hasError('email')
        ? 'Not a valid email'
        : '';
  }



  submit() {
    if (this.reactiveForm.valid) {
      console.log(this.reactiveForm.value);
      this.doc.update(this.reactiveForm.value);
    }
  }

  rmp(obj: { [x: string]: any; hasOwnProperty: (arg0: string) => any; }, propName: string) {

    for (const p in obj) {

      if (obj.hasOwnProperty(p)) {

        if (p === propName) {
          delete obj[p];

        } else if (typeof obj[p] === 'object') {
          this.rmp(obj[p], propName);
        }
      }
    }
    return obj;
  }

  flatten(data: any) {
    const result: any[] = [];
    function recurse(cur: any, prop: any) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        const l = cur.length;
        for (let i = 0; i < l; i++) {
          recurse(cur[i], prop + '[' + i + ']');
        }
        if (l === 0) {
          result[prop] = [];
        }
      } else {
        let isEmpty = true;
        for (const p of Object.keys(cur)) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + ' -> ' + p : p);
        }
        if (isEmpty && prop) {
          result.push({ path: prop, name: prop.split(' -> ').slice(-1).pop() });
        }
      }
    }
    recurse(data, '');
    return result;
  }

  addSelectedSkills() {
    const docs: any[] = [];
    this.skill_ids.forEach((skill: any) => {
      const ref = this.afs.firestore.collection('skill').doc(skill);
      this.doc.collection('skills').doc(skill).set({
        ref
      });
      docs.push(ref.get());
    });

    Promise.all(docs).then(documentSnapshotArray => {
      documentSnapshotArray.forEach((doc, idx) => {
        const dat = doc.data();
        this.doc.collection('skills').doc(dat.id).update({
          id: dat.id,
          label: dat.label,
          number: dat.number,
          path: dat.path,
          addr: dat.labels_address,
          short_addr: dat.labels_address.slice(0, -1),
          short_path: dat.labels_address.slice(0, -1).join(' => ')
        });
      });
    }).then(() => {
      this.addactive = false;
    });
    // console.log(this.skill_ids)
  }

  suggestNewSkill(): void {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = false;
    this.matDialog.open(SuggestNewSkillComponent, dialogConfig);
  }

  userrmreq() {
      console.log({remove_req: true})
      this.doc.update({remove_req: true});

      this.afs.collection('requests').add({
        reason: 'usr_rm_req',
        type: 'user',
        objectID: this.id,
        creator: this.id,
        time: Date.now()
      });
      
  }

  reinstate() {
    console.log({remove_req: false})
    this.doc.update({remove_req: false});
    this.rmprof = false;
    this.rmprofw = false;
  }

  uploadAttachment(event: any): void {
    this.selectedFiles = event.target.files;
    this.upload()
  }

  upload(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.selectedFiles) {
        const file: File | null = this.selectedFiles.item(0);
        this.selectedFiles = undefined;
        if (file) {
          this.currentFileUpload = new FileUpload(file);
          this.uploadService.pushFileToStorage(this.currentFileUpload, 'profile-pic').subscribe(percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
            if (this.percentage > 99) {
              resolve(true);
            }
          }, err => {
            console.log(err)
            reject(err)
          })
        }
      }
    })
  }

}



