import { Component, OnInit } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { map, Observable, of } from 'rxjs';

import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { UserService } from '../../service/user.service';
import { FileUpload } from 'src/app/model/file-upload';
import { UploadService } from 'src/app/service/upload.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PublicPostComponent } from 'src/app/shared/public-post/public-post.component';
import { SuggestNewSkillComponent } from 'src/app/shared/suggest-new-skill/suggest-new-skill.component';

@Component({
  selector: 'app-commmunity',
  templateUrl: './commmunity.component.html',
  styleUrls: ['./commmunity.component.scss']
})
export class CommmunityComponent implements OnInit {

  currentDate = new Date();
  posts: Observable<any[]>;
  skills: Array<any> = [];
  showtext = false;
  postsCollection: AngularFirestoreCollection<any>;
  value: string = '';

  reportsCollection: AngularFirestoreCollection<any>;
  report: boolean = false;
  reportID: string = '';
  reoprtReason: string = '';
  enableComment: string = '';
  reportForm: UntypedFormGroup;

  postForm: UntypedFormGroup;
  userdata: any;
  selectedFiles?: FileList;
  currentFileUpload?: FileUpload;
  percentage = 0;
  loading: boolean = false;


  constructor(
    public auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private fb: UntypedFormBuilder,
    public userService: UserService,
    private uploadService: UploadService,
    private dialog: MatDialog,
  ) {

    this.posts = firestore.collection('posts', ref => ref.orderBy('time', 'desc')).valueChanges({ idField: 'postId' });


    this.postsCollection = firestore.collection('posts');
    this.reportsCollection = firestore.collection('reports');

    this.postForm = this.fb.group({
      body: ['', [Validators.required]]
    });

    this.reportForm = this.fb.group({
      body: ['', [Validators.required]]
    });

    this.userService.user$.subscribe(data => {
      this.userdata = data;
    });

  }

  ngOnInit(): void {
    this.firestore.collection('skill').get().subscribe((snapshot) => {
      this.skills = snapshot.docs.map(e => {
        const data = e.data()
        const id = e.id;
        return { id, ...(data as {}) }
      })
    })
  }



  post() {
    if (this.postForm.valid) {
      const d = new Date();
      this.postsCollection.add({
        title: this.userdata.displayName + '\'s post',
        avatar: this.userdata.photoURL,
        body: this.postForm.value.body,
        creator: this.userdata.uid,
        subtitle: 'Created on ' + d,
        time: Date.now()
      });
      this.showtext = false;

    }
  }

  reportUser() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = false;
    dialogConfig.minWidth = "700px"
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = { reportUser: true }
    this.dialog.open(SuggestNewSkillComponent, dialogConfig);
  }

  reportFn() {
    if (this.reportForm.valid) {
      const d = new Date();
      this.reportsCollection.add({
        reason: this.reportForm.value.body,
        objectID: this.reportID,
        type: 'POST',
        creator: this.userdata.uid,
        time: Date.now()
      });
      this.report = false;

    }
  }

  likePost(id: any, isExist: boolean) {
    const postsRef = this.postsCollection.doc(id);
    if (isExist) {
      postsRef.update({
        likedBy: firebase.firestore.FieldValue.arrayRemove(this.userdata.uid)
      })
    } else {
      postsRef.update({
        unlikeBy: firebase.firestore.FieldValue.arrayRemove(this.userdata.uid)
      })
      postsRef.update({
        likedBy: firebase.firestore.FieldValue.arrayUnion(this.userdata.uid)
      })
    }
  }

  unlikePost(id: any, isExist: boolean) {
    const postsRef = this.postsCollection.doc(id);
    if (isExist) {

      postsRef.update({
        unlikeBy: firebase.firestore.FieldValue.arrayRemove(this.userdata.uid)
      })
    } else {
      postsRef.update({
        likedBy: firebase.firestore.FieldValue.arrayRemove(this.userdata.uid)
      })
      postsRef.update({
        unlikeBy: firebase.firestore.FieldValue.arrayUnion(this.userdata.uid)
      })
    }
  }

  uploadAttachment(event: any): void {
    this.selectedFiles = event.target.files;
    this.upload();
  }

  upload(): void {
    this.loading = true;
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;
      if (file) {
        this.currentFileUpload = new FileUpload(file);
        this.uploadService.pushFileToStorage(this.currentFileUpload, 'posts').subscribe(percentage => {
          this.percentage = Math.round(percentage ? percentage : 0);
          setTimeout(() => {
            this.loading = false;
          }, 4000)
        }, err => console.log(err))
      }
    }
  }

  openPostEditor(): void {
    const config = new MatDialogConfig();
    config.width = '50%';
    config.hasBackdrop = true;
    config.disableClose = true;
    config.autoFocus = false;
    config.data = {
      userdata: this.userdata
    }
    this.dialog.open(PublicPostComponent, config)
  }

}
