import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/service/notification.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  user$ = this.userService.user$;
  notifications: Array<any> = [];
  notificationsCount: number = 0;

  constructor(public userService: UserService, private afs: AngularFirestore, private notification: NotificationService, private router: Router) { }

  search: boolean = false;

  ngOnInit(): void {
    this.getNotifications()
  }

  getNotifications() {
    const users: any = {};
    this.afs.collection('users').get().subscribe((snapshot) => {
      snapshot.forEach((doc) => {
        users[doc.id] = doc.data();
      })
      this.notification.getNotification().subscribe(snapshot => {
        this.notifications = snapshot.map(e => {
          const data: any = e.payload.doc.data();
          const id = e.payload.doc.id;
          data.fromName = users[data.from]?.displayName
          return { id, ...(data as {}) }
        })

        console.log(' this.notifications', this.notifications);

        const notificationsCount = this.notifications.filter((notification: any) => {
          return notification.status == 0;
        })
        this.notificationsCount = notificationsCount.length;
      })
    })
  }

  markAllRead(): void {
    this.notifications.forEach(notification => {
      this.afs.collection('notifications').doc(notification.id).update({
        status: 1
      })
    })
  }

  updateNotificationStatus(notification: any) {
    this.afs.collection('notifications').doc(notification.id).update({
      status: 1
    })
    if (notification.action) {
      const url = notification.pageUrl + `?f=${notification.from}&t=${notification.to}&key=${notification.id}`
      this.router.navigateByUrl(url);
    }
  }

  searchToogle() {
    this.search = !this.search;
  }

}
