import { Component, OnInit } from '@angular/core';

import { UserService } from '../../service/user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { PresenceService } from 'src/app/service/presence.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  user$ = this.userService.user$;

  constructor(
    public auth: AngularFireAuth,
    public userService: UserService,
    private presense: PresenceService
  ) { }

  ngOnInit(): void {
  }

  async logout() {
    await this.presense.setPresence(0);
    this.auth.signOut().then(() => {
      window.location.reload();
    })
  }
}
