<table mat-table [dataSource]="dataSource" class="mat-elevation-z0 w-100 projects">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Title </th>
    <td mat-cell *matCellDef="let element"> {{element.title}} </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button color="accent" *ngIf="element.admin" (click)="createOrEditProject(element.id)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button color="accent" (click)="viewProject(element)">
        <mat-icon>visibility</mat-icon>
      </button>
      <button color="accent" mat-icon-button *ngIf="element.admin" (click)="shareProject(element)">
        <mat-icon>share</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="visibility">
    <th mat-header-cell *matHeaderCellDef>Change Visibility</th>
    <td mat-cell *matCellDef="let element">
      <button color="accent" *ngIf="element.creator == this.userdata.uid" mat-raised-button (click)="updateProjectType(element.id, element.type)">
        {{(element.type == 'public') ? 'Make Private' : 'Make Public'}}
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-card class="d-flex justify-content-center" *ngIf="loading">
  <mat-spinner [diameter]="40"></mat-spinner>
</mat-card>
<div class="container mt-4">
  <div class="d-flex justify-content-end" style="gap: 10px">
    <button mat-raised-button color="accent" (click)="createOrEditProject()">
      Create New Project
    </button>
    <a mat-raised-button color="accent" (click)="publicProjects()">
      Public Projects
    </a>
  </div>
</div>
