<mat-card *ngFor="let bond of bonds" class="mb-2">
  <mat-card-content *ngIf="userdata.uid == userId || bond.status == 'active' ; else pending_bond">
    <div class="d-flex justify-content-between">
      <div class="profile-section">
        <img [src]="bond.photoUrl" alt="Profile image" class="profile-img">
        {{bond.username}}
      </div>

      <div class="action">
        <button mat-stroked-button (click)="viewProfile(bond.uid)">View Profile</button>&nbsp;
        <ng-container *ngIf="userdata.uid == userId">
          <ng-container *ngIf="bond.status == 'pending' && bond.uid != userdata?.uid">
            <button mat-button (click)="updateBondStatus(bond, false)">Ignore</button>&nbsp;
            <button mat-raised-button color="accent" (click)="updateBondStatus(bond, true)">Accept</button>
          </ng-container>
          <ng-container *ngIf="bond.status == 'sent'">
            <button mat-button disabled>Request Sent</button>
            <button mat-raised-button color="accent" (click)="removeBondRequest(bond)">Delete Request</button>
          </ng-container>
          <ng-container *ngIf="bond.status == 'active'">
            <button mat-raised-button color="accent" (click)="removeBondRequest(bond)">Remove Bond</button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </mat-card-content>

  <ng-template #pending_bond>
    <mat-card-content>
      <div class="d-flex justify-content-between">
        <div class="profile-section">
          <img src="assets/images/profile-icon.jpg" alt="Profile image" class="profile-img">
          Pending bond request
        </div>
      </div>
    </mat-card-content>


  </ng-template>
</mat-card>
<mat-card *ngIf="bonds.length == 0">
  <mat-card-content>
    <h3>No active bonds.</h3>
  </mat-card-content>
</mat-card>
