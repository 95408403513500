import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  title: string = '';
  message: string = '';
  rejectButton: string = 'No';
  acceptButton: string = 'Yes';

  constructor(
    private dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.title = data.title
    this.message = data.message
    this.rejectButton = data.button.rejectButton;
    this.acceptButton = data.button.acceptButton
  }

  ngOnInit(): void {
  }

  closeDialog(status: boolean): void {
    this.dialogRef.close({ status })
  }

}
