import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FileUpload } from 'src/app/model/file-upload';
import { ProjectService } from 'src/app/service/project.service';
import { UploadService } from 'src/app/service/upload.service';
import { ScrollToBottomDirective } from '../scroll-to-bottom.directive';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, AfterViewChecked {
  @ViewChild('scrollMe') private myScrollContainer!: ElementRef;

  @Input() projectId: any;
  @Input() userId: any;

  @ViewChild(ScrollToBottomDirective)

  scroll!: ScrollToBottomDirective;
  imageTypes = ['png', 'jpg', 'jpeg', 'gif'];

  message: any;
  messages: Array<any> = []
  disableScrollDown: boolean = false
  loading: boolean = false;
  selectedFiles?: FileList;
  currentFileUpload?: FileUpload;
  percentage: number = 0;
  attachmentLoading: boolean = false;

  constructor(private projectService: ProjectService, private afs: AngularFirestore, private uploadService: UploadService) { }

  ngOnInit(): void {
    this.getMessages();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  getMessages() {
    this.loading = true
    let users: any = {};
    this.afs.collection('users').get().subscribe((snapshot) => {
      snapshot.forEach((doc) => {
        users[doc.id] = doc.data();
      })
      this.afs.collection('projects').doc(this.projectId).collection('chat', ref => ref.orderBy('dateSent', 'asc')).snapshotChanges().subscribe(snapshot => {
        const messages = snapshot.map((e) => {
          const data: any = e.payload.doc.data();
          const id = e.payload.doc.id;
          data.userName = users[data.fromId]?.displayName;
          data.userImage = users[data.fromId]?.photoURL
          return { id, ...(data as {}) };
        })
        this.messages = messages;
        this.loading = false;
      })
    })
  }

  onScroll() {
    let element = this.myScrollContainer.nativeElement;
    let atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (this.disableScrollDown && atBottom) {
      this.disableScrollDown = false;
    } else {
      this.disableScrollDown = true;
    }
  }

  private scrollToBottom(): void {
    if (this.disableScrollDown) {
      return
    }
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  sendMessage() {
    if (this.message) {
      let messageObject = {
        dateSent: new Date(),
        fromId: this.userId,
        message: this.message,
        type: 1
      }
      this.projectService.sendMessage(messageObject, this.projectId).then().catch(err => {
        console.log('Error while send message => ', err);
      })
      this.message = '';
    }
  }

  uploadAttachment(event: any): void {
    this.selectedFiles = event.target.files;
    this.upload();
  }

  upload() {
    this.attachmentLoading = true;
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;
      if (file) {
        this.currentFileUpload = new FileUpload(file);
        this.uploadService.pushFileToStorage(this.currentFileUpload, 'messages', { projectId: this.projectId }).subscribe(percentage => {
          this.percentage = Math.round(percentage ? percentage : 0);
          this.attachmentLoading = false;
        }, err => console.log(err))
      }
    }
  }
}
