import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { tap, first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

export enum Status {
  offline = 0,
  active = 1,
  away = 2,
  busy = 3
}

@Injectable({
  providedIn: 'root'
})
export class PresenceService {

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) {
    // this.updateOnUser().subscribe();
    this.updateOnDisconnect().subscribe();
    // this.updateOnAway();
  }


  getPresence(uid: string) {
    return this.afs.collection('users').doc(uid).valueChanges();
  }

  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }


  async setPresence(status: Status) {
    const user = await this.getUser();
    if (user) {
      return this.afs.collection('users').doc(user.uid).update({ status });
    }
  }

  // updateOnUser() {
  //   const connection = this.afs.object('.info/connected').valueChanges().pipe(
  //     map(connected => connected ? 1 : 0)
  //   );

  //   return this.afAuth.authState.pipe(
  //     switchMap(user => user ? connection : of(0)),
  //     tap(status => this.setPresence(status))
  //   );
  // }

  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap(user => {
        if (user) {
          firebase.database().ref().child(`users/${user.uid}`).onDisconnect().update({ status: 0 })
        }
      })
    );
  }

  async signOut() {
    await this.setPresence(0);
    await this.afAuth.signOut();
  }

  updateOnAway() {
    document.onvisibilitychange = (e) => {
      if (document.visibilityState === 'hidden') {
        this.setPresence(2);
      } else {
        this.setPresence(1);
      }
    };
  }

}
