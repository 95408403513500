<div class="container-fluid mt-4">
  <mat-card-title class="mb-3">Public Projects</mat-card-title>
  <div class="d-flex justify-content-end pb-3">
    <button mat-fab (click)="searchToggle = !searchToggle">
      <mat-icon>{{searchToggle? 'clear' : 'search'}}</mat-icon>
    </button>
    <mat-card class="search-panel" *ngIf="searchToggle">
      <form [formGroup]="searchProjectForm">
        <div class="d-flex justify-content-end flex-column">
          <mat-form-field>
            <input matInput placeholder="Search by title or keyword" formControlName="title">
          </mat-form-field>
          <skill-select-widgit #skill (selections)="skillIds = $event" [multilevel]="true"></skill-select-widgit>
          <div class="d-flex justify-content-between" style="gap:10px">
            <button mat-raised-button color="accent" class="w-100" (click)="searchProjects()">Search</button>
            <button mat-raised-button class="w-100" (click)="[skill.reset(), resetProjects()]">Reset</button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Title </th>
      <td mat-cell *matCellDef="let element">
        <strong>{{element.title}}</strong>
      </td>
    </ng-container>
    <ng-container matColumnDef="creator">
      <th mat-header-cell *matHeaderCellDef>Assignee</th>
      <td mat-cell *matCellDef="let element">
        <div class="avatar" [style.backgroundColor]="element.color">
          <div class="avatar__letters">
            {{element.creator.charAt(0)}}
          </div>
        </div>
        {{element.creator}}
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Created At</th>
      <td mat-cell *matCellDef="let element">
        {{element.createdAt | date }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="accent" (click)="viewProject(element)">
          <mat-icon>visibility</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-card class="d-flex justify-content-center" *ngIf="loading">
    <mat-spinner [diameter]="40"></mat-spinner>
  </mat-card>
  <mat-card *ngIf="!loading && emptyProjects">
    <mat-card-subtitle class="text-center">No projects match search creteria.</mat-card-subtitle>
  </mat-card>
</div>
