<ng-container *ngIf="(apiLoaded | async)">
  <ng-container *ngIf="user$ | async as user;">
    <google-map 
      [center]="{ lat: user.pos.geopoint.latitude, lng: user.pos.geopoint.longitude }" 
      [zoom]="zoom"
      [options]="options"
    >
      <map-circle 
        [center]="{ lat: user.pos.geopoint.latitude, lng: user.pos.geopoint.longitude }" 
        [radius]="radius * 1000"
        [options]="{ fillColor: '#309b90AA', strokeColor: '#309b90'}"
      ></map-circle>

      <map-marker 
        [position]="{ lat: user.pos.geopoint.latitude, lng: user.pos.geopoint.longitude }" 
        [options]="{ draggable: false, title: 'Me', icon: '/assets/images/bcv_pin_person.svg'}"
      ></map-marker>

      <map-marker-clusterer 
        [imagePath]="markerClustererImagePath"
      >
        <!-- <map-marker *ngFor="let markerPosition of markerPositions" [clickable]="true" (mapClick)="click()" [position]="markerPosition"
          [options]="markerOptions">
        </map-marker> -->
      
        <map-marker 
          *ngFor="let point of points | async"
          (mapClick)="highlightUser(point?.uid)" 
          [clickable]="true"
          [position]="{ lat:point.pos.geopoint.latitude, lng:point.pos.geopoint.longitude }" 
          [options]="{ draggable: false, title: point.displayName, icon: '/assets/images/bcv_pin.svg' }"
        ></map-marker>

        <map-marker 
        [position]="{ lat: user.pos.geopoint.latitude, lng: user.pos.geopoint.longitude }" 
        [options]="{ draggable: false, title: 'Highlight'}"
      ></map-marker>

      </map-marker-clusterer>
    </google-map>

    <div class="search-handle" matTooltip="Drag me around" cdkDrag>
      <button mat-fab (click)="opened = !opened">
        <mat-icon *ngIf="!opened">search</mat-icon>
        <mat-icon *ngIf="opened">close</mat-icon>
      </button>
      <button mat-raised-button color="accent" (click)="opened = !opened" class='bg-btn'>
        <mat-icon aria-label="">search</mat-icon> Search the map
      </button>
    </div>

    <mat-card class="tools" *ngIf="opened">
      <mat-card-content class="overflow-auto" style="height: calc(100vh - 150px);">
        <br>
        <mat-form-field class="map-search w-100">
          <mat-label>Search users by name</mat-label>
          <input matInput type="text" [(ngModel)]="search" (input)="searchFn2()">
          <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <br>
        <div class="skill-box">
          <skill-select-widgit #skill (selections)="skillIds = $event; searchFn2()" [multilevel]="true"></skill-select-widgit>
        </div>
        <p>
          Search distance:
        </p>
        <p>
          <mat-slider thumbLabel tickInterval="auto" min="1" max="50" [(ngModel)]="radius"></mat-slider>
        </p>
        <mat-button-toggle-group [(ngModel)]="radius" (change)="searchFn2()">
          <mat-button-toggle [value]="1">1km</mat-button-toggle>
          <mat-button-toggle [value]="5">5km</mat-button-toggle>
          <mat-button-toggle [value]="10">10km</mat-button-toggle>
          <mat-button-toggle [value]="25">25km</mat-button-toggle>
          <mat-button-toggle [value]="100">100km</mat-button-toggle>
          <mat-button-toggle [value]="40000">Global</mat-button-toggle>
        </mat-button-toggle-group>


        <p class="d-flex" style="gap:5px;">
          <button mat-stroked-button color="accent" (click)='searchFn2()'>Search</button>
          <button mat-stroked-button color="accent" (click)='[skill.reset(), reset()]'>Reset</button>
          <button mat-stroked-button color="accent" (click)="suggestNewSkill()">Suggest or Edit Skill</button>
        </p>


        <br>
        <p>{{ (points | async)?.length ? (points | async)?.length : 0 }} users found in {{ radius }}km radius.</p>
        <br>
        <mat-card *ngFor="let point of points | async" class="mb-2" id="user-{{point.uid}}">
          <mat-card-content>
            <div class="d-flex justify-content-between align-items-center">
              <mat-card-title class="d-flex align-items-center">
                <img class="round" src="{{point.photoURL}}">
                <span> {{ point.displayName }}</span>
              </mat-card-title>
              <button class="mt-0" mat-icon-button (click)="removeUser(point.uid)">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <p>Top 3 Skills:</p>
                <skill-list-widget [displayedColumns]="['icon', 'name']" [userid]="point.uid" [showPath]="false"
                  [list]="true">
                </skill-list-widget>
              </div>
              <div class="actions d-flex align-items-center" style="gap:5px">
                <button mat-raised-button (click)="removeBondRequest(point?.uid)"
                  *ngIf="point.status == 'active' || point.status == 'sent'">Remove Bond</button>
                <button mat-raised-button *ngIf="!point.status" (click)="sendBondRequest(point.uid)">Bond</button>
                <button mat-raised-button (click)="redirectUser(point.uid)">View profile</button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </mat-card>
</ng-container>
</ng-container>
