<form [formGroup]="commentForm">
  <div class="row mt-3">
    <div class="col-sm-9">
      <mat-form-field class="w-100" appearance="fill">
        <input matInput autocomplete="off" formControlName="comment" placeholder="Add your comment">
      </mat-form-field>
    </div>
    <div class="col-sm-3">
      <button mat-raised-button [disabled]="commentForm.invalid" (click)="addComment()">Comment</button>
    </div>
  </div>
</form>
