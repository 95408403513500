import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/service/alert.service';
import { ProjectService } from 'src/app/service/project.service';
import { UserService } from 'src/app/service/user.service';
import { CreateProjectComponent } from '../create-project/create-project.component';
import { ShareProjectComponent } from '../share-project/share-project.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  @Input() userId: any;

  userdata: any;
  // projects!: Array<any>;
  displayedColumns: string[] = ['name', 'visibility', 'action'];
  dataSource = new MatTableDataSource<any>();
  users: any;
  loading: boolean = true;
  subscribe!: Subscription;

  constructor(
    private firestore: AngularFirestore,
    private route: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private alert: AlertService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subscribe = this.userService.user$.subscribe(data => {
      this.userdata = data;
      this.firestore.collection('users').valueChanges().subscribe(users => {
        this.users = users.filter((user: any) => {
          return user?.uid != this.userdata?.uid
        });
      });
    });
    this.projectService.userId = this.userId;
    this.firestore.collection('projects').valueChanges().subscribe(() => {
      this.getProjects()
    })
  }

  async getProjects() {
    this.projectService.getProjects().then(res => {
      this.dataSource.data = res;
      this.loading = false;
    }).catch(err => {
      console.log('Error while loading Projects: =>', err);
    })
  }

  viewProject(project: any): void {
    this.route.navigate([`project/${project.id}`])
  }

  createOrEditProject(projectId: any = ''): void {
    const data: any = { user: this.userdata, users: this.users };
    if (projectId) {
      data.projectId = projectId;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.data = data;
    const dialog = this.dialog.open(CreateProjectComponent, dialogConfig);
    dialog.afterClosed().subscribe(() => {
      // this.getProjects();
    })
  }

  shareProject(project: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '40%';
    dialogConfig.data = { project, userId: this.userId }
    this.dialog.open(ShareProjectComponent, dialogConfig);
  }

  updateProjectType(projectId: string, type: string) {
    if (type == 'private') {
      this.alert.confirm(
        'Are you sure do you want to make your Project Public?',
        'Public projects are visible to all users and searchable in the database',
        'Yes, make public',
        'No, keep private'
      ).then(res => {
        if (res) {
          this.firestore.collection('projects').doc(projectId).update({
            type: 'public'
          }).then(() => {
          })
        } else {
        }
      }).catch(err => console.log(err))
    } else {
      this.firestore.collection('projects').doc(projectId).update({
        type: 'private'
      }).then(() => {
      })
    }
  }

  publicProjects() {
    this.route.navigate(['public-projects'])
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe()
    this.dataSource.data = [];
  }
}
