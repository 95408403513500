<div class="container">
  <div class="text-center">
    <mat-card-title class="title">{{title}}</mat-card-title>
    <h4 class="mt-4" *ngIf="message">{{message}}</h4>
  </div>
</div>
<mat-dialog-actions class="d-flex justify-content-center">
  <button mat-raised-button color="primary" (click)="closeDialog(false)">{{rejectButton}}</button>
  <button mat-raised-button color="accent" (click)="closeDialog(true)">{{acceptButton}}</button>
</mat-dialog-actions>
