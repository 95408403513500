import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeRoutingModule } from './theme-routing.module';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { UserMenuComponent } from './user-menu/user-menu.component';

import { NgChatModule } from 'ng-chat';
import { ChatComponent } from './chat/chat.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from '../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [
    AuthLayoutComponent,
    MainLayoutComponent,
    UserMenuComponent,
    ChatComponent,
  ],
  imports: [
    CommonModule,
    ThemeRoutingModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSlideToggleModule,
    NgChatModule,
    MatBadgeModule,
    MatDividerModule,
    SharedModule,
    MatCardModule,
    MatListModule,
    MatTooltipModule
  ]
})
export class ThemeModule { }
