<h2 mat-dialog-title>{{title}}</h2>
<div class="d-flex justify-content-center" *ngIf="loading">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>
<form [formGroup]="projectForm" *ngIf="!loading">
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput placeholder="Title" formControlName="title">
    </mat-form-field>
    <mat-radio-group [disabled]="!access" aria-label="Select an option" formControlName="type">
      <mat-radio-button value="public">Public</mat-radio-button> &nbsp;&nbsp;&nbsp;
      <mat-radio-button value="private">Private</mat-radio-button>
    </mat-radio-group>
    <mat-form-field>
      <mat-label>Purpose</mat-label>
      <textarea matInput rows="5" formControlName="description"></textarea>
    </mat-form-field>
    <!-- <mat-form-field>
      <mat-label>Select Team...</mat-label>
      <mat-select formControlName="team" multiple>
        <mat-select-trigger>
          <mat-chip-list>
            <ng-container *ngFor="let t of projectForm.get('team')?.value">
            <mat-chip *ngIf="t != userdata.uid" [removable]="true" (removed)="removeTeam(t)">
              {{ t | userById:users}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          </mat-chip-list>
        </mat-select-trigger>
        <mat-form-field class="search-filter">
          <input matInput placeholder="Search Team Members..." (keyup)="filterUsers($event)">
        </mat-form-field>
        <ng-container *ngFor="let user of users.sort() | filterUser:filterUser">
          <mat-option [value]="user.uid">{{user.displayName}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field> -->
    <skill-select-widgit (selections)="skillIds = $event" [skills]="project?.skills" [multilevel]="false">
    </skill-select-widgit>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="saveProject()" [disabled]="projectForm.invalid || submit">{{submit
      ?
      'Loading...' :'Save'}}</button>
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</form>
