<div class="d-flex justify-content-between">
  <h2 mat-dialog-title>Share Project</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>clear</mat-icon>
  </button>
</div>
<mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="shareWith">
  <!-- <mat-radio-button class="example-radio-button" value="1">
    Share with everyone
  </mat-radio-button> -->
  <!---new changes 28-10-22-- code commented only-->
  <!-- <mat-radio-button class="example-radio-button" value="1">
    Share with my bonds
  </mat-radio-button> -->
    <!---new changes 28-10-22---end--->
</mat-radio-group>
<ng-container *ngIf="shareWith == 1">
  <div class="d-flex flex-column">
     <!---new changes 28-10-22-- code commented only-->
    <!-- <mat-form-field class="w-50">
      <input matInput placeholder="Search my bond" [(ngModel)]="filterTeam" autocomplete="off">
    </mat-form-field> -->
     <!---new changes 28-10-22-- end-->
    <ng-container *ngFor="let t of team">
      <mat-checkbox [value]="t.uid" (change)="checkboxChange($event)">
        {{t.username}}
      </mat-checkbox>
    </ng-container>
    <mat-card class="bg-danger text-white" *ngIf="team.length == 0">
      <mat-card-content>
        No bonds found
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="accent" [disabled]="sharing" (click)="shareProject()">
    {{sharing? 'Sharing...' : 'Share'}}
  </button>
</mat-dialog-actions>
