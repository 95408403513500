import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/compat/app'
import * as geofire from 'geofire-common';
import { UserService } from 'src/app/service/user.service';
import { BondsService } from 'src/app/service/bonds.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SuggestNewSkillComponent } from 'src/app/shared/suggest-new-skill/suggest-new-skill.component';
import { AlertService } from 'src/app/service/alert.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss']
})
export class GmapComponent implements OnInit {
  points: Observable<any> = new Observable<any>();
  search = '';
  skillIds: Array<string> = [];
  searchMap: boolean = false;
  bonds: any = [];
  currentUserPosition: any = {};
  user: any;
  user$ = this.userService.user$;

  markerClustererImagePath =
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
  apiLoaded!: Observable<boolean>;
  // center: google.maps.LatLngAltitudeLiteral = { lat: 52.2, lng: 0.12, altitude: 0 }
  zoom = 10

  options = {
    // scrollwheel: false,
    backgroundColor: "#309b90",
    styles : [
       {
         featureType: "poi",
         stylers: [
          { visibility: "off" }
         ]   
       },
       // map shapes
       {
         "elementType": "geometry",
        "stylers": [
          {
            color: "#d8f3f0"
          }
        ]
      },
//rail
      {
        "featureType": "transit.line",
    "elementType": "geometry",
        "stylers": [
          {
            "color": "#dddddd"
          }
        ]
      },

      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#63cfc4"
          }
        ]
      },

// forests
  {
    "featureType": "landscape.natural",
    "elementType": "geometry",
    "stylers": [
      {
        color: '#8adbd3'
      }
    ]
  },
  
// roads
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        color: "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dddddd"
      }
    ]
  },

  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#cccccc"
      }
    ]
  },

  //rivers
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#d8e6f3"
      }
    ]
  },
  
    ]
  };

  // circleCenter: google.maps.LatLngLiteral = { lat: 52.2, lng: 0.12 };
  radius = 5;
  tooltip: string = 'skdjksdj'
  // markerOptionsCurrentUser: google.maps.MarkerOptions = { draggable: false, title: 'Me' };
  // markerOptions: google.maps.MarkerOptions = { draggable: false, title: 'Me', icon: '/assets/images/bcv_pin.svg' };
  markerPositions: google.maps.LatLngLiteral[] = [];
  // animation = google.maps.Animation.BOUNCE;

  opened = true;

  constructor(
    private httpclient: HttpClient,
    private userService: UserService,
    private bondsService: BondsService,
    private matDialog: MatDialog,
    private alertService: AlertService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.apiLoaded = this.httpclient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.GoogleMapsApi}`, 'callback')
      .pipe(map(() => true), catchError((error) => {
        console.log(error);
        return of(false)
      }));
  }

  ngOnInit(): void {
    this.userService.user$.subscribe((user: any) => {
      this.user = user;
      this.currentUserPosition = { lat: user.pos.geopoint.latitude, lng: user.pos.geopoint.longitude };
      console.log(this.currentUserPosition)
      // this.circleCenter = this.currentUserPosition;
      this.searchFn2()
    })
  }

  suggestNewSkill(): void {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = false;
    this.matDialog.open(SuggestNewSkillComponent, dialogConfig);
  }

  searchFn2() {
    this.searchMap = true;
    const center: any = [this.currentUserPosition.lat, this.currentUserPosition.lng];
    const radiusInM = 1000 * this.radius;
    const bounds = geofire.geohashQueryBounds(center, radiusInM);

    const db = firebase.firestore();

    const promises = [];
    for (const b of bounds) {
      if (this.radius > 100) { // Remove radius condition for global and search all users
        const q = db.collection('users')
          .where('pos.geohash', '!=', null)
          .orderBy('pos.geohash')
        promises.push(q.get());
        console.clear()
      } else {
        const q = db.collection('users')
          .where('pos.geohash', '!=', null)
          .orderBy('pos.geohash')
          .startAt(b[0])
          .endAt(b[1]);
        promises.push(q.get());
      }
    }

    // Collect all the query results together into a single list
    Promise.all(promises).then((snapshots) => {
      const matchingDocs = [];

      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const geopoint = doc.get('pos.geopoint');
          const lat = geopoint.latitude;
          const lng = geopoint.longitude;

          const searchText = doc.get('displayName').toLowerCase();
          const userSkills = doc.get('skills');

          let skillFound = true;

          if (this.skillIds) {
            if (this.skillIds.length > 0) {
              if (userSkills) {
                skillFound = userSkills.some((r: string) => this.skillIds.includes(r));
              } else {
                skillFound = false;
              }
            }
          }

          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = geofire.distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;

          if (distanceInM <= radiusInM) {
            if (searchText.match(this.search.toLowerCase())) {
              if (skillFound) {
                const list = doc.data();
                list.hitMetadata = { distance: distanceInM / 1000 };

                // Randomiose point loactions by small distance
                list.pos.geopoint._lat = list.pos.geopoint._lat + ((Math.random() - 0.5) * 0.05);
                list.pos.geopoint._lon = list.pos.geopoint._lat + ((Math.random() - 0.5) * 0.05);

                if (this.user.uid != list.uid) {
                  matchingDocs.push(list);
                }
              }
            }
          }
        }
      }
      return matchingDocs;
    }).then((matchingDocs) => {
      // console.clear()
      // console.log(matchingDocs)
      this.points = of(matchingDocs);
      this.userService.userId$.subscribe((userId: any) => {
        this.bondsService.listBonds(userId).subscribe(snapshot => {
          this.bonds = snapshot.map(e => {
            const data = e.payload.doc.data();
            const id = e.payload.doc.id;
            return { id, ...(data as {}) }
          })
          matchingDocs.map((doc, index) => {
            const bond = this.bonds.find((e: any) => e.uid === doc.uid)
            if (bond) {
              matchingDocs[index].status = bond?.status;
            }
          })
          this.points = of(matchingDocs);
        })
      })
    });
  }

  async highlightUser(uid: any) {
    if(!this.opened) {
      this.opened = true;
      await new Promise(f => setTimeout(f, 1000));
    }

    const user = document.getElementById(`user-${uid}`);
    if (user) {
      
      user.scrollIntoView()
      user.style.border = '2px solid red';
      setTimeout(() => {
        user.style.border = '';
      }, 1000)
    }
  }

  redirectUser(userId: string) {
    this.router.navigate([`/user/${userId}/view`])
  }


 

  sendBondRequest(id: string): void {
    this.alertService.confirm('Confirmation', 'Do you want to add this User to your Bonds list?').then((res => {
      if (res) {
        this.bondsService.sendRequest(id, 'pending').then(() => {
          this.snackBar.open('Bond request sent successfully', 'Got it', {
            duration: 5000
          });
        })
      }
    }))
  }

  removeBondRequest(id: string) {
    this.alertService.confirm('Confirmation', ' Do you want to remove this User from your Bonds list?').then(res => {
      if (res) {
        this.bondsService.remove(id).then(() => {
          this.snackBar.open('Bond removed successfully', 'Got it', {
            duration: 5000
          });
        })
      }
    })
  }


  reset(): void {
    this.searchMap = false;
    this.search = '';
    this.points = of([]);
  }

  removeUser(userId: any) {
    this.points.subscribe(points => {
      const _points = points.filter((point: any) => {
        return point.uid != userId;
      })
      this.points = of(_points)
    })
  }

}
