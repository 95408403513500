import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserService } from './user.service';

export declare enum MessageType {
  Text = 1,
  File = 2,
  Image = 3
}
export interface Message {
  type?: MessageType;
  fromId: any;
  message: string;
  dateSent?: Date;
}
@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  userId: any;

  constructor(private afs: AngularFirestore) { }

  getAdminRoleProjects(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afs.collection('projects', ref => ref.where('team', 'array-contains', { id: this.userId, role: 'admin' })).snapshotChanges().subscribe(snapshot => {
        const projects = snapshot.map((e) => {
          const data: any = e.payload.doc.data();
          data.admin = true
          const id = e.payload.doc.id;
          return { id, ...(data as {}) }
        })
        resolve(projects)
      })
    })
  }

  getTeamRoleProjects(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afs.collection('projects', ref => ref.where('team', 'array-contains', { id: this.userId, role: 'team' })).snapshotChanges().subscribe(snapshot => {
        const projects = snapshot.map((e) => {
          const data: any = e.payload.doc.data();
          data.admin = false;
          const id = e.payload.doc.id;
          return { id, ...(data as {}) }
        })
        resolve(projects)
      })
    })
  }

  async getProjects() {
    const [admin, team] = await Promise.all([
      this.getAdminRoleProjects(),
      this.getTeamRoleProjects()
    ])

    const projectsArray = admin.concat(team);
    return projectsArray;
  }

  get publicProjects(): Promise<any> {
    return new Promise((resolve, reject) => {
      let users: any = {};
      this.afs.collection('users').get().subscribe((snapshot) => {
        snapshot.forEach((doc) => {
          users[doc.id] = doc.data();
        })
        this.afs.collection('projects', ref => ref.where('type', '==', 'public')).get().toPromise().then(snapshot => {
          const projects = snapshot?.docs.map((doc) => {
            const data: any = doc.data();
            const id = doc.id;
            data.creator = users[data.creator]?.displayName;
            return { id, ...(data as {}) };
          })
          resolve(projects);
        }).catch(err => {
          reject(err)
        })
      })
    })
  }

  filterProject(title: string, skills: Array<any>): Promise<any> {
    let filteredData: any = [];
    return new Promise((resolve, reject) => {
      this.publicProjects.then((projects: Array<any>) => {
        filteredData = projects.filter(project => {
          if (typeof project.skills != 'undefined' && skills.length > 0) {
            const _projectData = project.skills.filter((skill: any) => {
              return skills.includes(skill);
            })
            if (_projectData.length) {
              return project;
            }
          } else {
            return project;
          }
        })
        if (title) {
          filteredData = filteredData.filter((project: any) => {
            if (project.title?.toLowerCase()?.includes(title?.toLowerCase()) || project.keywords?.find((key: any) => key.includes(title?.toLowerCase()))) {
              return project;
            }
          })
        }
        resolve(filteredData);
      })
    })
  }

  getProject(id: string) {
    return this.afs.collection('projects').doc(id).snapshotChanges();
  }

  sendMessage(message: Message, projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afs.collection('projects').doc(projectId).collection('chat').add(message).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err)
      });
    })
  }

  deleteProject(id: string) {
    return this.afs.collection('projects').doc(id).delete();
  }
}
