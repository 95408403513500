import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BondsService {

  userId: any;
  usersCollection: AngularFirestoreCollection<any>
  emailCollection: AngularFirestoreCollection<any>

  constructor(private afs: AngularFirestore, private users: UserService) {
    this.usersCollection = this.afs.collection('users');
    this.emailCollection = this.afs.collection('mail' );
    this.users.userId$.subscribe((userId) => {
      this.userId = userId;
    })
  }

  sendRequest(to: string, status: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let bonds = [this.userId, to].sort()
      let bond_id = bonds[0] + '-' +bonds[1]
      console.log(bond_id);

      this.usersCollection.doc(to).collection('bonds').doc(bond_id).ref.get().then((doc) => {
        if (doc.exists) {
            alert('ERROR: Bond exists!')
            resolve(false)
        } else {
          this.usersCollection.doc(to).collection('bonds').doc(bond_id).set({ // Save in user profile
            uid: this.userId, status,
          }).then((response) => {
            this.usersCollection.doc(this.userId).collection('bonds').doc(bond_id).set({ // Save in logged in user profile
              uid: to, status: 'sent', docId: bond_id
            }).then((res) => {
              this.usersCollection.doc(to).collection('bonds').doc(bond_id).update({
                docId: bond_id
              })
              
              
              this.usersCollection.doc(to).ref.get().then(val => {
                console.log(val)
                var address = val.get('email');
                var name = val.get('displayName');

                console.log(address)
                console.log(name)

                var email = { 
                  message: {
                      html: 'Hi ' + name + ',<br>You have a new bond request! Please visit https://app.becovalent.co.uk to learn more.',
                      subject: 'You have a new bond request on BeCoValent!',
                      text: 'You have a new bond request!'
                  },
                  to: [ address ]
                };
                this.emailCollection.add(email);

              });
          
              
              resolve(true)
            })
          }).catch(err => {
            reject(err)
          })
        }
      }).catch((err) => {
        reject(err)
      });
    })
  }

  listBonds(id: string) {
    return this.usersCollection.doc(id).collection('bonds').snapshotChanges();
  }

  listActiveBonds(id: string) {
    return this.usersCollection.doc(id).collection('bonds', ref => ref.where('status', '==', 'active')).snapshotChanges();
  }

  async approveRequest(firstUserBondId: string = '', secondUserBondId: string = '', secondUserId: string = ''): Promise<any> {
    this.usersCollection.doc(this.userId).collection('bonds').doc(firstUserBondId).update({ status: 'active' })
    this.usersCollection.doc(secondUserId).collection('bonds').doc(secondUserBondId).update({ status: 'active' })
    return new Promise((resolve, reject) => {
      resolve(true)
    })
  }

  async remove(firstUserBondId: string = '', secondUserBondId: string = '', secondUserId: string = '') {
    this.usersCollection.doc(secondUserId).collection('bonds').doc(secondUserBondId).delete()
    this.usersCollection.doc(this.userId).collection('bonds').doc(firstUserBondId).delete()
    return new Promise((resolve, reject) => {
      resolve(true)
    })
  }
}
