<div *ngIf="user$ | async as user; else noChat">

  <!-- <div class="group-btn backdrop">
      <mat-icon aria-hidden="false" class="person" aria-label="person">person</mat-icon>
      <mat-slide-toggle class="tgl tgl-mute" size="large" color="accent" [checked]="groups" (change)="onChangeGroup($event)">
          <mat-icon aria-hidden="false" class="group" aria-label="group">group</mat-icon>
      </mat-slide-toggle>
  </div> -->

  <ng-chat #userChat *ngIf="adapter" [adapter]="adapter" [isCollapsed]="collapsed" [userId]="user.uid" [title]="'Chat with my bonds'"
    (onMessagesSeen)=messageSeen($event)></ng-chat>
  <!--ng-chat *ngIf="groups" [adapter]="groupAdapter" [groupAdapter]="groupAdapter" [userId]="user.uid" [title]="'Groups'" (onMessagesSeen)=messageSeen($event)></ng-chat-->
</div>


<ng-template #noChat>
</ng-template>
`
