import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainLayoutComponent } from '../theme/main-layout/main-layout.component';
import { ProfileLayoutComponent } from './profile-layout/profile-layout.component';
import { ViewComponent } from './view/view.component';
import { EditComponent } from './edit/edit.component';

const routes: Routes = [
  {
  path: '',
  component: MainLayoutComponent,
  children: [
    {
      path: ':id', component: ProfileLayoutComponent,
      children: [
        { path: '', redirectTo: 'view', pathMatch: 'full' },
        { path: 'edit', component: EditComponent },
        { path: 'view', component: ViewComponent },
        { path: 'view/:tabIndex', component: ViewComponent },
        // { path: 'message', component: BcvProfileMessageComponent },
        // { path: 'bond', component: BcvProfileBondComponent },
      ],
    },
  ]
  }


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
