<mat-list *ngIf="comments.length > 0">
  <div mat-subheader>Comments</div>
  <mat-list-item *ngFor="let c of comments">
    <mat-icon mat-list-icon>comment</mat-icon>
    <div mat-line class="d-flex align-items-center justify-content-between">
      <p>
        <span class="userName">{{c.userName}}</span>  {{c.comment}}
      </p>
      
      <button *ngIf="userId === c.commentedBy" color="warn" mat-icon-button (click)="removeComment(c.id)">

        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
  </mat-list-item>
</mat-list>
