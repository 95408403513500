import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  chatTrigger = new BehaviorSubject({});

  constructor(
    private auth: AngularFireAuth,
    private afs: AngularFirestore
  ) {

    // this.auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     this.updateUserStatus(1);
    //   } else {
    //     this.updateUserStatus(0);
    //   }
    // })
  }

  userId$ = this.auth.authState.pipe(
    switchMap(user => {
      if (user) {
        return new BehaviorSubject(user.uid);
      } else {
        return new BehaviorSubject(null);
      }
    })
  );

  user$ = this.auth.authState.pipe(
    switchMap(user => {
      if (user) {
        const ref = this.afs.doc<any>(`users/${user.uid}`);
        return ref.valueChanges();
      } else {
        return of(null);
      }
    })
  );

  messages$ = this.auth.authState.pipe(
    switchMap(user => {
      if (user) {
        return this.afs.collection('users').doc(user.uid).collection('messages').valueChanges();
      } else {
        return of(null);
      }
    })
  );

  // bonds$ = this.auth.authState.pipe(
  //   switchMap(user => {
  //     if (user) {
  //       return this.afs.collection('users').doc(user.uid).collection('bonds').valueChanges();
  //     } else {
  //       return of(null);
  //     }
  //   })
  // );

  userById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afs.collection('users').doc(id).get().toPromise().then(res => {
        resolve(res?.data())
      }).catch(err => reject(err));
    })
  }
}
