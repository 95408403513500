import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { CreateProjectComponent } from 'src/app/shared/project/create-project/create-project.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'profile-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  pendingBonds$: number = 0;
  public id: string = "";
  public id$: Subject<string> = new BehaviorSubject<string>('');
  public doc!: AngularFirestoreDocument<any>;
  public user!: Observable<any>;
  public tabIndex: any;
  public displayedColumns: string[] = ['icon', 'name', 'rating', 'endorsments']
  userdata: any;
  users: any;

  constructor(private route: ActivatedRoute, private afs: AngularFirestore, private userService: UserService, private dialog: MatDialog) { }

  ngOnInit() {
    this?.route?.parent?.paramMap.subscribe(params => {
      this.id = params.get('id') || '';
      this.id$.next(params.get('id') || '');
      this.doc = this.afs.collection('users').doc(this.id);
      this.user = this.doc.valueChanges();
      this.tabIndex = this.route.snapshot.paramMap.get('tabIndex');
    });

    this.userService.user$.subscribe(data => {
      this.userdata = data;
      if (this.userdata?.uid != this.id) {
        this.displayedColumns.push('endorse_btn')
      }
      this.afs.collection('users').valueChanges().subscribe(users => {
        this.users = users.filter((user: any) => {
          return user?.uid != this.userdata?.uid
        });
      });
    });
  }

  pendingBonds(event: any) {
    this.pendingBonds$ = event.length;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabIndex = tabChangeEvent.index;
  }

}
