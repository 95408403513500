<h2 mat-dialog-title>Share a public post</h2>
<mat-dialog-content>
  <form [formGroup]="postForm">
    <mat-card>
      <mat-card-subtitle>Title</mat-card-subtitle>
      <mat-card-content>
        <mat-form-field class="w-100">
          <input matInput #title placeholder="Answer" formControlName="title">
          <mat-hint align="end">{{title.value.length}} / 50</mat-hint>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-subtitle>Content </mat-card-subtitle>
      <mat-card-content>
        <mat-form-field class="w-100">
          <textarea matInput #content placeholder="Answer" formControlName="content"></textarea>
          <mat-hint align="end">{{content.value.length}} / 500</mat-hint>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-subtitle>Linked Skills</mat-card-subtitle>
      <mat-card-content>
        <skill-select-widgit #skill (selections)="skillIds = $event" [multilevel]="false"></skill-select-widgit>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-subtitle>Attachment (max of 1MB file size)</mat-card-subtitle>
      <mat-card-content>
        <button color="primary" mat-stroked-button (click)="upload.click()">
          <mat-icon>upload</mat-icon> Add file
        </button>
        <input type="file" class="d-none" #upload (change)="uploadAttachment($event)">
      </mat-card-content>
    </mat-card>
  </form>
</mat-dialog-content>
<mat-card-actions class="mb-0">
  <button mat-raised-button color="accent" (click)="post()" [disabled]="loading">
    {{loading ? 'Uploading...' :'Post'}}
  </button>
  <button mat-stroked-button color="accent" mat-dialog-close>Discard</button>
</mat-card-actions>
