import { Injectable } from '@angular/core';

//import { MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { of } from 'rxjs';
import { filter, catchError, tap, map, switchMap, debounceTime } from 'rxjs/operators';
// import { fromPromise } from 'rxjs/operators';

import { from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MapGeocoder } from '@angular/google-maps';
import { environment } from 'src/environments/environment';

export interface Location {
  lat: number;
  lng: number;
  addr: string;
  spankm: number;
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {

  // private geocoder: any;
  apiLoaded!: Observable<boolean>;


  constructor(private httpclient: HttpClient,
    private geocoder: MapGeocoder) {

    // this.apiLoaded = this.httpclient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.GoogleMapsApi}`, 'callback')
    //   .pipe(map(() => true), catchError((error) => {
    //     console.log(error);
    //     return of(false)
    //   }));
  }

  private initGeocoder() {
    console.log('Init geocoder!');
    // this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      // return from(this.mapLoader.load())
      // .pipe(
      //  tap(() => this.initGeocoder()),
      //  map(() => true)
      // );
    }
    return of(true);
  }

  private getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  private deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  geocodeAddress(location: string): Observable<Location> {
    return this.waitForMapsToLoad().pipe(
      debounceTime(5000),
      switchMap(() => {
        return new Observable<Location>(observer => {
          this.geocoder.geocode({
            // address: '1600 Amphitheatre Parkway, Mountain View, CA'
            address: location
          }).subscribe(({ results }) => {
            let spankm = 0;
            if (results.length == 0) {
              observer.next()
            } else {
              if (results[0].geometry.bounds) {
                spankm = this.getDistanceFromLatLonInKm(
                  results[0].geometry.bounds.getNorthEast().lat(),
                  results[0].geometry.bounds.getNorthEast().lng(),
                  results[0].geometry.bounds.getSouthWest().lat(),
                  results[0].geometry.bounds.getSouthWest().lng()
                )
              }
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
                addr: results[0].formatted_address,
                spankm,
                success: true
              })
            }
            observer.complete()
          });
        });
      })
    );
  }


}


