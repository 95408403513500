import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUser'
})

export class FilterUserPipe implements PipeTransform {
  transform(value: any, args: string) {
    let array = value
    array = array?.filter((v: any) => {
      return v.displayName.toLowerCase().includes(args.toLowerCase())
    })
    array.sort((a: any, b: any) => {
      let fa = a.displayName.toLowerCase(),
        fb = b.displayName.toLowerCase()
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1
      }
      return 0;
    })
    return array;
  }
}
