import { ChatAdapter, IChatGroupAdapter, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant } from 'ng-chat';
import { Observable, of, throwError, concat, merge, zip, forkJoin } from 'rxjs';
import { delay, switchMap, mergeAll, concatAll, mergeMap } from "rxjs/operators";

import { take, takeLast } from 'rxjs/operators';



import { map, catchError } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

export class DemoAdapter extends ChatAdapter implements IChatGroupAdapter {

  users: Observable<any[]>;
  myid: any;
  messages: AngularFirestoreCollection<any>;
  resolvedId: string | undefined;
  usersRef: AngularFirestoreCollection<any>;
  counters: any;

  constructor(
    private usr: Observable<any[]>,
    private id: any,
    private mgs: AngularFirestoreCollection<any>,
    private usersReference: AngularFirestoreCollection<any>
  ) {
    super();
    this.users = usr;
    this.myid = id;
    this.messages = mgs;
    this.usersRef = usersReference;

    // console.log('setID: ', DemoAdapter?.userId)



    id.subscribe((uid: any) => {
      console.log('init uid:', uid);
      this.resolvedId = uid;
    })
    // .pipe(takeLast(1))
    id.pipe(
      switchMap((uid: string) => {
        console.log('inbox id: ', uid);
        return usersReference.doc(uid).collection(
          'inbox',
          ref => ref.orderBy('dateSent', 'desc').limit(1)
        ).valueChanges();
      })
    ).subscribe((msg: any) => {
      console.log('inbox: ', msg[0]);
      if (msg[0]) {

        if (msg[0].toId === this.resolvedId) {
          let chatUser = this.activeParticipants.find(x => x.id == msg[0].fromId);
          if (chatUser) this.onMessageReceived(chatUser, msg[0]);

        } else {
          let chatUser = this.activeParticipants.find(x => x.id == msg[0].toId);
          if (chatUser) this.onMessageReceived(chatUser, msg[0]);
        }


        // let chatUser = {
        //       participantType: ChatParticipantType.User,
        //       id: replyMessage.fromId,
        //       displayName: 'boot',
        //       avatar: '',
        //       status: ChatParticipantStatus.Online
        //   }


      }


    });


    id.pipe(
      switchMap((uid: string) => {
        console.log('inbox id: ', uid);
        return usersReference.doc(uid).collection('inbox').doc('counters').valueChanges();
      })
    ).subscribe((countersInstance: any) => {
      console.log('countersInstance: ', countersInstance);
      this.counters = countersInstance;
    });


    let groupObserver: Observable<any> = this.myid.pipe(
      switchMap((uid: string) => {
        return this.usersRef.doc(uid).collection('chatGroups').valueChanges();
      })
    );

    let userObserver: Observable<any> = this.users;



    // groupObserver.subscribe((x: any) => {


    //   x.map((data: any) => {
    //     let existingUser = this.activeParticipants.find(x => x.id == data.id);
    //     if (existingUser) {

    //     } else {
    //       this.activeParticipants.push(data);
    //     }

    //   })
    //   //console.log(this.activeGroups)
    //   this.listFriends().subscribe(response => {
    //     this.onFriendsListChanged(response);
    //   });
    // })

    userObserver.subscribe((x: any) => {
      x.map((userGroup: any) => {
        if (userGroup?.status) {

        }
        let chatUser = {
          participantType: ChatParticipantType.User,
          id: userGroup.uid,
          displayName: userGroup.displayName,
          avatar: userGroup.photoURL,
          status: ChatParticipantStatus.Offline
        }

        // switch (Number(userGroup?.status)) {
        //   case 1:
        //     chatUser.status = ChatParticipantStatus.Online
        //     break;
        //   case 2:
        //     chatUser.status = ChatParticipantStatus.Away
        //     break;
        //   case 3:
        //     chatUser.status = ChatParticipantStatus.Busy
        //     break;
        //   default:
        //     chatUser.status = ChatParticipantStatus.Offline
        //     break;
        // }

        if (userGroup.uid !== this.resolvedId) {
          this.activeParticipants.push(chatUser);
        }


      })

      this.listFriends().subscribe(response => {
        this.onFriendsListChanged(response);
      });
    })




  }

  public activeParticipants: any[] = [];



  cerateChatUsr(userGroup: any): any {


    console.log('before if ', userGroup)

    if (userGroup.participantType === ChatParticipantType.Group) {
      console.log('group: ', userGroup);
      this.activeParticipants.push(userGroup);


    } else {
      console.log('usr: ', userGroup.uid);

      let chatUser = {
        participantType: ChatParticipantType.User,
        id: userGroup.uid,
        displayName: userGroup.displayName,
        avatar: userGroup.photoURL,
        status: ChatParticipantStatus.Online
      }

      this.activeParticipants.push(chatUser);


    }


  }

  listFriends(): Observable<ParticipantResponse[]> {
    // List connected users to show in the friends list
    // Sending the userId from the request body as this is just a demo
    /*
            console.log('fire in user')

            let groupObserver: Observable<any> = this.myid.pipe(
              switchMap((uid: string) => {
                return this.usersRef.doc(uid).collection('chatGroups').valueChanges();
              })
            );

            let userObserver: Observable<any> =  this.users;


            function schedule() {
              return of(userObserver, groupObserver);
            }
             // return forkJoin( {userObserver, groupObserver} )

             // return userObserver; // concat()

             let commonObserver: Observable<any> = of(groupObserver, userObserver).pipe(
              mergeAll(),
              map((res: any) => res.map((userGroup: any) => {
                  //


                  let participantResponse = new ParticipantResponse();

                  console.log('before if ', userGroup)

                  if(userGroup.participantType === ChatParticipantType.Group) {
                    console.log('group: ', userGroup);
                    DemoAdapter.activeParticipants.push(userGroup);
                    participantResponse.participant = userGroup;

                  } else {
                    console.log('usr: ', userGroup.uid);

                    let chatUser = {
                      participantType: ChatParticipantType.User,
                      id: userGroup.uid,
                      displayName: userGroup.displayName,
                      avatar: userGroup.photoURL,
                      status: ChatParticipantStatus.Online
                    }

                    DemoAdapter.activeParticipants.push(chatUser);
                    participantResponse.participant = chatUser;

                  }



                  // console.log((this.counters)? this.counters[user.uid]? this.counters[user.uid] : 0 : 0)

                participantResponse.metadata = {
                  totalUnreadMessages: (this.counters)? this.counters[participantResponse.participant.id]? this.counters[participantResponse.participant.id] : 0 : 0 // Math.floor(Math.random() * 10)
                }
                return participantResponse;

              }).filter((user: any) => {
                if (user.participant.id === this.resolvedId) {
                  console.log('FO: ', user.participant.id)
                  return false; // skip
                }
                // console.log('NFO: ', user.participant.id)
                return true;
              })),
              catchError((error: any) => throwError(error.error || 'Server error'))
            );

          */
    // return forkJoin( {userObserver, groupObserver} )
    return of(this.activeParticipants.map((user: any) => {
      let participantResponse = new ParticipantResponse();

      participantResponse.participant = user;
      participantResponse.metadata = {
        totalUnreadMessages: (this.counters) ? this.counters[participantResponse.participant.id] ? this.counters[participantResponse.participant.id] : 0 : 0
      }

      return participantResponse;
    }).filter((user: any) => {
      if (user.participant.id === this.resolvedId) { return false; } return true;
    }));
    // return commonObserver; // concat()

    //return schedule().pipe(
    //  mergeAll(),
    //); // concat()


  }



  getMessageHistory(destinataryId: any): Observable<Message[]> {
    // let mockedHistory: Array<Message>;
    // console.log('conn: ', destinataryId)
    // console.log('sender:', this.resolvedId);

    let chatUser = this.activeParticipants.find(x => x.id == destinataryId);
    console.log('hist req, sender:', chatUser);

    let conversationId: string;
    if (chatUser?.participantType === 0) {
      conversationId = [this.resolvedId, destinataryId].sort().join('-');
    } else {
      conversationId = destinataryId;
    }

    let collection = this.messages.doc(conversationId).collection('conv').valueChanges();

    return collection.pipe(
      take(1),
      map((res: any) => res.map((msg: any) => {
        // console.log('usr: ', user.uid);

        return msg;
      })),
      catchError((error: any) => throwError(error.error || 'Server error'))
    );
  }

  sendMessage(message: Message): void {
    console.log('Sent msg:', message)

    let chatUser = this.activeParticipants.find(x => x.id == message.toId);
    console.log('msg sent, to:', chatUser);

    let messageObject = {
      dateSent: message.dateSent?.toISOString(),
      fromId: message.fromId,
      message: message.message,
      toId: message.toId,
      type: message.type
    }

    let conversationId: string;
    if (chatUser?.participantType === 0) {
      conversationId = [message.toId, message.fromId].sort().join('-');

      this.usersRef.doc(message.toId).collection('inbox').doc(message.dateSent?.toISOString()).set(messageObject);
      const increment = firebase.firestore.FieldValue.increment(1);
      this.usersRef.doc(message.toId).collection('inbox').doc('counters').set({
        [message.fromId]: increment
      }, { merge: true });

    } else {
      conversationId = message.toId;
      console.log('setting up group inboxes: ', chatUser)

      chatUser.chattingTo.map((usr: any) => {
        if (usr.id !== message.fromId) {

          this.usersRef.doc(usr.id).collection('inbox').doc(message.dateSent?.toISOString()).set(messageObject);
          const increment = firebase.firestore.FieldValue.increment(1);
          this.usersRef.doc(usr.id).collection('inbox').doc('counters').set({
            [message.toId]: increment
          }, { merge: true });
        }
      })


    }

    this.messages.doc(conversationId).collection('conv').doc(message.dateSent?.toISOString()).set(messageObject);

    // let conversationId = [message.toId, message.fromId].sort().join('-');








    // let replyMessage = new Message();

    // replyMessage.message = "You have typed '" + message.message + "'";
    // replyMessage.dateSent = new Date();

    // replyMessage.fromId = message.toId;
    // replyMessage.toId = message.fromId;


    // let chatUser = {
    //       participantType: ChatParticipantType.User,
    //       id: replyMessage.fromId,
    //       displayName: 'boot',
    //       avatar: '',
    //       status: ChatParticipantStatus.Online
    //   }

    // this.onMessageReceived(chatUser, replyMessage);






  }

  groupCreated(group: Group): void {
    console.log('created group: ', group)

    group.chattingTo.push(
      {
        participantType: 0,
        id: this.resolvedId,
        displayName: "",
        avatar: "",
        status: 0
      }
    )
    group.displayName = 'Group [' + group.displayName + this.resolvedId + ']';

    group.chattingTo.map((user: any) => {
      this.usersRef.doc(user.id).collection('chatGroups').doc(group.id).set(Object.assign({}, group));
    })

    // this.activeParticipants.push(group);

    // this.listFriends().subscribe(response => {
    //  this.onFriendsListChanged(response);
    // });


    //DemoAdapter.activeParticipants = DemoAdapter.activeParticipants.sort((first, second) =>
    //  second.displayName > first.displayName ? -1 : 1
    //);

    // Trigger update of friends list
    //this.listFriends().subscribe(response => {
    //  this.onFriendsListChanged(response);
    //});
  }

}
