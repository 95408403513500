import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  todo: Array<any> = [];
  progress: Array<any> = [];
  done: Array<any> = [];

  loading: boolean = true;

  note: any;
  projectsCollection: AngularFirestoreCollection<any>;

  addNoteTodo: boolean = false;
  addNoteProgress: boolean = false;
  addNoteDone: boolean = false;

  projectId: any;
  changesDetected: boolean = false;

  constructor(private afs: AngularFirestore, private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar) {
    this.projectsCollection = this.afs.collection('projects');
  }

  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getTasks();
  }

  getTasks(): void {
    this.afs.collection(`projects`).doc(this.projectId).collection('tasks').valueChanges().subscribe(tasks => {
      tasks.forEach(task => {
        if (task.todo.length > 0) {
          this.todo = task.todo;
        }
        if (task.progress.length > 0) {
          this.progress = task.progress;
        }
        if (task.done.length > 0) {
          this.done = task.done;
        }
      })
      this.loading = false;
    })
  }

  reset() {
    this.todo = [];
    this.progress = []
    this.done = []
    this.getTasks();
    this.changesDetected = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.changesDetected = true;
  }

  addNote(type: any): void {
    switch (type) {
      case 'todo':
        this.todo.push(this.note)
        break;
      case 'progress':
        this.progress.push(this.note);
        break;
      case 'done':
        this.done.push(this.note)
        break;
    }
    this.note = undefined;
    this.setNotes();

    this.addNoteTodo = false;
    this.addNoteProgress = false;
    this.addNoteDone = false;
  }

  setNotes() {
    const data = {
      todo: this.todo,
      progress: this.progress,
      done: this.done
    };
    this.projectsCollection.doc(this.projectId).collection('tasks').doc(this.projectId).set(data);
    this.snackBar.open('Task updated successfully', 'Got it', {
      duration: 5000
    })
    this.changesDetected = false;
  }

  removeItem(type: string, index: number) {
    if (type == 'todo') {
      this.todo.splice(index, 1)
    } else if (type == 'progress') {
      this.progress.splice(index, 1)
    } else if (type == 'done') {
      this.done.splice(index, 1)
    }
    setTimeout(() => {
      this.setNotes()
    }, 1000);
  }
}
