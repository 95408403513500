import { NgModule } from '@angular/core';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { customClaims } from '@angular/fire/compat/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

const redirectUnregistered = () => pipe(customClaims,
  map((claims) => {
    return claims.registered ? true : ['auth', 'welcome'];
  })
);

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
  {
    path: 'user', data: { authGuardPipe: redirectUnregistered },
    canActivate: [AngularFireAuthGuard], loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
