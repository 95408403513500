<div id="frame">
  <div class="content">
    <div class="messages"
      [ngClass]="[loading || !messages.length ? 'd-flex align-items-center justify-content-center' : '']" #scrollMe
      (scroll)="onScroll()">
      <mat-spinner *ngIf="loading" [diameter]="60"></mat-spinner>
      <img *ngIf="!loading && !messages.length" [ngStyle]="{height:'200px'}" src="/assets/images/opened.svg" alt="">
      <ul>
        <ng-container *ngFor="let msg of messages">
          <li *ngIf="msg.type == 1" [ngClass]="[userId == msg.fromId ? 'replies' : 'sent']">
            <img [src]="msg.userImage" class="user-img" alt="" />
            <p class="d-flex flex-column" style="overflow-wrap: anywhere;">
              <span>{{msg.message}}</span>
              <span style="font-size:8px;" class="border-top mt-2">
                {{msg.userName}} - {{msg.dateSent.seconds * 1000 | date }}
              </span>
            </p>
          </li>
          <li *ngIf="msg.type == 2" [ngClass]="[userId == msg.fromId ? 'replies' : 'sent']">
            <img [src]="msg.userImage" class="user-img" alt="" />
            <p class="d-flex flex-column" style="overflow-wrap: anywhere;">
              <a [href]="msg.message" target="_blank">
                <img [src]="msg.message" width="100" height="auto" *ngIf="imageTypes.includes(msg?.fileName | imageType)" alt="">
        
              </a>
              <span style="font-size:8px;" class="border-top mt-3">
                {{msg.userName}} - {{msg.dateSent | dateFormat }}
              </span>
            </p>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="message-input">
      <input type="file" #attachment class="d-none" (change)="uploadAttachment($event)">
      <div class="d-flex align-items-center justify-content-between px-2 bg-white" style="gap:10px;">
        <mat-form-field class="w-100" appearance="outline">
          <textarea matInput placeholder="Write your message..." [(ngModel)]="message"></textarea>
        </mat-form-field>
        <button mat-button [disabled]="attachmentLoading" (click)="attachment.click()">
          <mat-icon>{{ attachmentLoading ? 'cloud_sync' : 'attachments'}}</mat-icon>
        </button>
        <button mat-raised-button color="accent" (click)="sendMessage()">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
