import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUpload } from 'src/app/model/file-upload';
import { UploadService } from 'src/app/service/upload.service';

@Component({
  selector: 'app-public-post',
  templateUrl: './public-post.component.html',
  styleUrls: ['./public-post.component.scss']
})
export class PublicPostComponent implements OnInit {

  postForm!: FormGroup;
  skillIds: any = [];
  selectedFiles?: FileList;
  currentFileUpload?: FileUpload;
  percentage: number = 0;
  postsCollection: AngularFirestoreCollection<any>;
  userdata: any;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private uploadService: UploadService,
    firestore: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<PublicPostComponent>
  ) {
    this.userdata = data.userdata
    this.postsCollection = firestore.collection('posts');
  }

  ngOnInit(): void {
    this.postForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      content: ['', [Validators.required, Validators.maxLength(500)]],
    })
  }

  uploadAttachment(event: any): void {
    this.selectedFiles = event.target.files;
  }

  upload(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!id) {
        reject(false)
        return
      }
      if (this.selectedFiles) {
        const file: File | null = this.selectedFiles.item(0);
        this.selectedFiles = undefined;
        if (file) {
          this.currentFileUpload = new FileUpload(file);
          this.uploadService.pushFileToStorage(this.currentFileUpload, 'public-posts', { id }).subscribe(percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
            if (this.percentage > 99) {
              resolve(true);
            }
          }, err => {
            console.log(err)
            reject(err)
          })
        }
      }
    })
  }


  post() {
    if (this.postForm.valid) {
      this.loading = true;
      this.postsCollection.add({
        title: this.postForm.value.title,
        body: this.postForm.value.content,
        skills: this.skillIds,
        creatorName: this.userdata?.displayName,
        subtitle: 'on ' + new Date(),
        attachment: '',
        date: new Date(),
        creator: this.userdata?.uid,
        time: Date.now()
      }).then((response) => {
        this.upload(response.id).then(() => {
          this.loading = false;
          this.dialogRef.close();
        })
      })
    } else {
      this.postForm.markAllAsTouched()
    }
  }

}
