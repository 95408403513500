import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-suggest-new-skill',
  templateUrl: './suggest-new-skill.component.html',
  styleUrls: ['./suggest-new-skill.component.scss']
})
export class SuggestNewSkillComponent implements OnInit {

  reportUser: boolean = false;

  constructor(private dialogRef: MatDialogRef<SuggestNewSkillComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.reportUser = data?.reportUser
  }

  ngOnInit(): void {
  }

}
