import { Component, OnInit } from '@angular/core';

// import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
