<div fxLayout="row" fxLayoutGap="10" *ngIf="!report">
  <div fxFlex="33%">
    <mat-card>
      <div class="d-flex justify-content-center">
        <mat-spinner [diameter]="60" *ngIf="!(user | async)"></mat-spinner>
      </div>
      <div class="text-center" *ngIf="user | async as usr">
        <img class="m-b-8 r-full" [src]="usr?.photoURL" alt="" width="80">
        <h2 class="m-t-0 m-b-8 f-w-400">{{ usr?.displayName }}</h2>
        <p>
          {{ usr?.geoaddr }}
        </p>

        <!--p class="m-t-0 m-b-8">[Top skills summary]</p-->
        <!--p class="m-t-0 text-grey-500">[Short bio]</p-->
        <div class="d-flex justify-content-center" style="gap:10px" *ngIf="usr.uid != userdata?.uid">
          <ng-container *ngIf="bond?.status == 'pending'">
            <button mat-stroked-button (click)="updateBondStatus(bond, false)">Ignore</button>
            <button mat-raised-button color="accent" (click)="updateBondStatus(bond, true)">Accept</button>
          </ng-container>
          <button mat-raised-button color="primary" *ngIf="!bond" (click)="sendBondRequest()">
            {{ loadingBondButton ? 'Loading...' : 'Bond' }}
          </button>
          <!---older-->
          <!-- <button mat-raised-button color="primary" *ngIf="bond?.status == 'sent' || bond?.status == 'active'"
            (click)="removeBondRequest(bond)">
            Remove Bond
          </button> -->
         <!---new puneet-->
          <button mat-raised-button color="primary" *ngIf="bond?.status == 'active'"
            (click)="removeBondRequest(bond)">
            Remove Bond
         </button>
          <button mat-raised-button color="accent" *ngIf="bond?.status == 'sent'">
            Pending Bond
          </button>
        <!---end-->

          <button mat-raised-button color="accent" class="m-l-8" *ngIf="bond?.status == 'active'"
            (click)="openChatWindow()"> Contact Me</button>
          <!--button mat-raised-button color="warn" (click)="reportUser()">Report User</button-->
          <button mat-raised-button color="warn" (click)="report=true; reportID=usr.uid;">Report User</button>

          
        </div>
      </div>
    </mat-card>

    <mat-card>
      <h2 class="m-t-0 m-b-8 f-w-400">Location</h2>
      <google-map
        [center]="{lat:convertNumber((user | async)?.pos.geopoint.latitude), lng:convertNumber((user | async)?.pos.geopoint.longitude)}"
        *ngIf="apiLoaded | async">
        <map-marker
          [position]="{lat:convertNumber((user | async)?.pos.geopoint.latitude), lng:convertNumber((user | async)?.pos.geopoint.longitude)}">
        </map-marker>
      </google-map>
    </mat-card>
  </div>
  <div fxFlex="66%">
    <router-outlet></router-outlet>

  </div>
</div>

<mat-card class="example-card" *ngIf="report">

  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      <mat-icon class='md-icon-2x'>report</mat-icon>
    </div>
    <mat-card-title>Report User</mat-card-title>
    <mat-card-subtitle>Why?</mat-card-subtitle>

  </mat-card-header>


  <form [formGroup]="reportForm" (ngSubmit)="reportFn()" *ngIf="true">

    <mat-form-field class="post-form-field" appearance="outline">
      <mat-label>Reasons</mat-label>
      <input matInput type="text" [(ngModel)]="reoprtReason" formControlName="body">
      <button mat-button *ngIf="reoprtReason" matSuffix mat-icon-button aria-label="Clear" (click)="reoprtReason=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button mat-flat-button color="accent" class="m-t-8">Report</button>
    <button mat-button class="m-t-8" (click)="report=false">Cancel</button>
  </form>

</mat-card>
