<div class="vertical-separator"></div>
<div class="vertical-separator"></div>

<div *ngIf="auth.user | async as user; else showLogin">
  <div class="" *ngIf="!report" >

    <h1 style="text-align: center;">{{userdata?.displayName}} BeCoValent news</h1>

    <div class="vertical-separator"></div>

    <mat-card class="example-card">
      <mat-card-header>
        <a *ngIf="userdata" (click)="openPostEditor()" style="cursor: pointer;" mat-card-avatar
          class="example-header-image">
          <mat-icon class='md-icon-2x'>edit</mat-icon>
        </a>
        <mat-card-title>Share a public post...</mat-card-title>
        <mat-card-subtitle>What's on your mind?</mat-card-subtitle>
        <div fxFlex></div>
      </mat-card-header>


      <form [formGroup]="postForm" (ngSubmit)="post()" *ngIf="showtext">

        <mat-form-field class="post-form-field" appearance="outline">
          <mat-label>Content</mat-label>
          <input matInput type="text" [(ngModel)]="value" formControlName="body">
          <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button mat-flat-button color="accent" class="m-t-8">Post</button>
        <button mat-button class="m-t-8" (click)="showtext=false">Cancel</button>
      </form>

      <mat-card-actions *ngIf="!showtext">
        <button mat-stroked-button color="accent" (click)="showtext=true">Text post</button>
        <!--button mat-stroked-button color="accent" (click)="file.click()">
          <mat-icon>collections</mat-icon> Photo/Video
        </button-->

        <input type="file" name="file" #file (change)="uploadAttachment($event)" [ngStyle]="{display: 'none'}">
      </mat-card-actions>
      <mat-progress-bar mode="determinate" [value]="percentage" *ngIf="loading"></mat-progress-bar>

    </mat-card>

    <div class="vertical-separator"></div>

    <mat-card class="example-card" *ngFor="let post of posts | async">

      <!-- Post added  with skills -->
      <ng-container *ngIf="post.skills">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image">
            <img src="{{post.avatar}}" alt="" class="avatar">
          </div>
          <mat-card-title>
            {{ post.title }}
          </mat-card-title>
          <mat-card-subtitle>
            By <a routerLink="/user/{{post.creator}}/view">{{post.creatorName}}</a> {{ post.subtitle | date:'full' }}
          </mat-card-subtitle>
          <!--div fxFlex></div>
          <div fxLayoutAlign="right top">
            <button mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
          </div-->
        </mat-card-header>
        <mat-card-content>
          <p>
            {{ post.body }}
          </p>
          <div class="d-flex align-items-center">
            <span class="me-2">Skills: </span>
            <mat-chip-list aria-label="">
              <mat-chip color="primary" *ngFor="let skill of post.skills">
                {{skill | skills:skills}}
              </mat-chip>
            </mat-chip-list>
          </div>
          <div class="d-flex align-items-center mt-2">
            <span class="me-2">Attachment: </span>
            <!-- <mat-chip-list aria-label="">
                  <mat-chip color="primary" *ngFor="let skill of post.skills">
                    {{skill | skills:skills}}
                  </mat-chip>
                </mat-chip-list> -->
            <div class="attachment">
              {{post.attachmentName}}
            </div>
          </div>
        </mat-card-content>
      </ng-container>

      <!-- Normal Post -->
      <ng-container *ngIf="!post.skills">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image">
            <img src="{{post.avatar}}" alt="" class="avatar">
          </div>
          <mat-card-title>
            <a routerLink="/user/{{post.creator}}/view"> {{ post.title }}</a>
          </mat-card-title>
          <mat-card-subtitle>{{ post.subtitle | date:'full' }}</mat-card-subtitle>
          <!--div fxFlex></div>
          <div fxLayoutAlign="right top">
            <button mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
          </div-->
        </mat-card-header>
        <img *ngIf="post.type == 'attachment'" src="{{ post.body }}" alt="...">
        <mat-card-content *ngIf="post.type != 'attachment'">
          <p>
            {{ post.body }}
          </p>
        </mat-card-content>
      </ng-container>
      <mat-card-actions>
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <button mat-icon-button matTooltip="Like" color="{{post.likedBy?.includes(userdata?.uid) ? 'accent' : ''}}"
              (click)="likePost(post.postId, post.likedBy?.includes(userdata?.uid))">
              <mat-icon>thumb_up</mat-icon>
              {{post.likedBy?.length}}
            </button>
            <button mat-icon-button matTooltip="Dislike"
              color="{{post.unlikeBy?.includes(userdata?.uid) ? 'accent' : ''}}"
              (click)="unlikePost(post.postId, post.unlikeBy?.includes(userdata?.uid))">
              <mat-icon>thumb_down</mat-icon>
              {{post.unlikeBy?.length}}
            </button>
            
            <button mat-icon-button (click)="enableComment = post.postId" matTooltip="Comment">
              <mat-icon>message</mat-icon>
            </button>
          </div>
          <div>
            <button mat-icon-button matTooltip="Report this Post" (click)="report=true; reportID=post.postId">
              <mat-icon>flag</mat-icon>
            </button>
          </div>
        </div>

        <app-add-comment *ngIf="userdata?.uid && enableComment == post.postId" [postId]="post.postId"
          [userId]="userdata?.uid">
        </app-add-comment>
      </mat-card-actions>
      <mat-card-content>
        <app-list-comments *ngIf="userdata?.uid && post.postId" [postId]="post.postId"  [userId]="userdata?.uid"></app-list-comments>
      </mat-card-content>
    </mat-card>

  </div>

</div>

<ng-template #showLogin>
  <p>User not found</p>
</ng-template>

<mat-card class="example-card" *ngIf="report">

  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      <mat-icon class='md-icon-2x'>report</mat-icon>
    </div>
    <mat-card-title>Report content</mat-card-title>
    <mat-card-subtitle>Why?</mat-card-subtitle>

  </mat-card-header>


  <form [formGroup]="reportForm" (ngSubmit)="reportFn()" *ngIf="true">

    <mat-form-field class="post-form-field" appearance="outline">
      <mat-label>Reasons</mat-label>
      <input matInput type="text" [(ngModel)]="reoprtReason" formControlName="body">
      <button mat-button *ngIf="reoprtReason" matSuffix mat-icon-button aria-label="Clear" (click)="reoprtReason=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button mat-flat-button color="accent" class="m-t-8">Report</button>
    <button mat-button class="m-t-8" (click)="report=false">Cancel</button>
  </form>


  <mat-progress-bar mode="determinate" [value]="percentage" *ngIf="loading"></mat-progress-bar>

</mat-card>
