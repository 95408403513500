import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { switchMap } from 'rxjs/operators';

export declare enum StatusType {
  read = 1,
  unread = 0
}
export interface Notification {
  status: StatusType;
  message: string;
  pageUrl?: string;
  date: any;
  from: string;
  to?: Array<any>;
  action?: boolean,
  type?: string,
  title?: string
}
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private afs: AngularFirestore, private auth: AngularFireAuth) { }

  getNotification() {
    return this.auth.authState.pipe(
      switchMap(user => {
        return this.afs.collection('notifications', ref => ref.where('to', 'array-contains', user?.uid).orderBy('date', 'desc')).snapshotChanges();
      })
    )
  }

  getNotificationById(id: string) {
    return this.afs.collection('notifications').doc(id).get();
  }

  sendNotification(notification: Notification) {
    return this.afs.collection('notifications').add(notification);
  }

  deleteNotification(id: string) {
    return this.afs.collection('notifications').doc(id).delete();
  }
}
