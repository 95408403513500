import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserService } from '../../service/user.service';

import { Input, Output, EventEmitter } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import firebase from 'firebase/compat/app';

import { AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'skill-list-widget',
  templateUrl: './skill-list-widget.component.html',
  styleUrls: ['./skill-list-widget.component.scss']
})
export class SkillListWidgetComponent implements OnInit {

  // user$ = this.userService.user$;
  skills = [];
  skills$!: Observable<firebase.firestore.DocumentData[]>;
  skillsDoc!: AngularFirestoreDocument<any>;
  rate = 3;
  cssRate = 2;
  faRate = 3;
  table = true;
  userdata: any = {};

  // displayedColumns: string[] = ['icon', 'name', 'weight', 'symbol'];
  @Input() displayedColumns: string[] = ['icon', 'name', 'rating', 'rm', 'endorsments', 'endorse_btn'];
  @Input() ratereadonly = true;
  @Input() list: boolean = false;

  @Input() userid: string = "";
  @Input() user$!: Observable<any>;
  @Input() id$!: Subject<string>;
  @Input() rmskills = false;
  @Input() showPath = true;


  constructor(
    private userService: UserService,
    private afs: AngularFirestore,
    private snack: MatSnackBar
  ) {

  }

  ngOnInit() {
    if (this.userid) {
      this.skills$ = this.afs.collection('users').doc(this.userid).collection('skills').valueChanges();
    } else {
      this.id$.subscribe((dat) => {
        this.userid = dat;
      });
      this.skills$ = this.id$.pipe(
        switchMap(uid => {
          return this.afs.collection('users').doc(uid).collection('skills').valueChanges();
        })
      );
    }

    this.userService.user$.subscribe((user) => {
      this.userdata = user
    })
  }

  ratefn($event: any, uid: any, skill: any) {
    this.afs.collection('users').doc(this.userid).collection('skills').doc(skill.id).update({ selfRating: $event });
  }

  endorsefn(skill: any) {
    this.afs.collection('users').doc(this.userid).collection('skills').doc(skill.id).update({ level: firebase.firestore.FieldValue.arrayUnion(this.userdata.uid) })
  }

  removeEndorse(skill: any) {
    this.afs.collection('users').doc(this.userid).collection('skills').doc(skill.id).update({ level: firebase.firestore.FieldValue.arrayRemove(this.userdata.uid) })
  }

  deleteSkill(id: string) {
    this.afs.collection('users').doc(this.userid).collection('skills').doc(id).delete().then(() => {
      this.snack.open('Skill deleted successfully', 'Got it', {
        duration: 5000
      })
    });
  }

  isNaN(value: any) {
    const n = Number(value);
    return n !== n;
  }

}
