
        
<mat-grid-list cols="2" rowHeight=200 gutterSize=20>
  <mat-grid-tile>



    <form [formGroup]="locationFormGroup">
      <mat-form-field appearance="outline">
        <mat-label>Postcode</mat-label>
        <input matInput formControlName="locationCtrl" placeholder="example: CB2 1QN" required>
        <button mat-raised-button color="accent" style="top: -8px" matSuffix type="submit" *ngIf="true" aria-label="Check">
          <mat-icon style="display: inline;">map</mat-icon>&nbsp;<b>Find</b>
        </button>

      </mat-form-field>


      <!--mat-form-field>
        <mat-label>map location</mat-label>
        <input matInput formControlName="mapCtrl" placeholder="">
      </mat-form-field-->


      <div *ngIf="postcode?.invalid && postcode?.dirty" class="alert alert-danger">
        <strong>{{ postcode?.value }}</strong> not found!
      </div>

      <div *ngIf="postcode?.valid" class="alert alert-success">
        Your approximate location: <strong>{{ location.addr }}</strong> [{{ location.lat }},{{ location.lng }}]
      </div>

      <div *ngIf="postcode?.pending" class="alert alert-info">
        Hold tight... checking: <strong>{{ postcode?.value }}</strong>
      </div>

      <div *ngIf="!postcode?.pending && !postcode?.valid && !(postcode?.invalid && postcode?.dirty)" class="alert alert-info">
        Please provide a posstcode, city or address

      </div>

       <button mat-flat-button color="accent" (click)="finalise()" [disabled]="!postcode?.valid">Change location</button>
    
    </form>

   

</mat-grid-tile>
  <mat-grid-tile>    
    <!--div class="map_placeholder">
      <agm-map
          *ngIf="location?.lat"
          [latitude]="location?.lat || 0"
          [longitude]="location?.lng || 0"
          [zoom]="8"
          [disableDefaultUI]="false">
          <agm-marker
              [latitude]="location?.lat || 0"
              [longitude]="location?.lng || 0">
          </agm-marker>

      </agm-map>
    </div-->
  </mat-grid-tile>

</mat-grid-list>
  

  
  

  
  