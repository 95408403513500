import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainLayoutComponent } from '../theme/main-layout/main-layout.component';

import { CommmunityComponent } from './commmunity/commmunity.component';
// import { MapComponent } from './map/map.component';
import { GmapComponent } from './gmap/gmap.component';

import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/compat/auth-guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);

import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { customClaims } from '@angular/fire/compat/auth-guard';
import { ViewProjectComponent } from './view-project/view-project.component';
import { PublicProjectsComponent } from './public-projects/public-projects.component';
import { ProjectPlaceholderComponent } from './project-placeholder/project-placeholder.component';

// import { ProjectsComponent } from '../shared/projects/projects.component';

const redirectUnregistered = () => pipe(customClaims,
  map((claims) => {
    // console.log(claims);
    return claims.registered ? true : ['auth', 'welcome'];
  })
);

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnregistered },
    children: [
      { path: '', component: GmapComponent, data: { title: 'Map', titleI18n: 'Map' } },
      // { path: 'map', component: MapComponent, data: { title: 'Map', titleI18n: 'Map' } },
      { path: 'news', component: CommmunityComponent, data: { title: 'Dashboard', titleI18n: 'Dashboard' } },
      { path: 'gmap', component: GmapComponent, data: { title: 'Map', titleI18n: 'Map' } },
      { path: 'project/:id', component: ViewProjectComponent, data: { title: 'Project', titleI18n: 'Project' } },
      { path: 'public-projects', component: PublicProjectsComponent, data: { title: 'Public Projects', titleI18n: 'Public Projects' } },
      { path: 'project-placeholder', component: ProjectPlaceholderComponent, data: { title: 'Projects', titleI18n: 'Projects' } }
    ],
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
