import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageType'
})

export class ImagePipe implements PipeTransform {
  transform(value: any) {
    return value.split('.').slice(-1).pop();
  }
}
