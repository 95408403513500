import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'skills'
})

export class SkillsPipe implements PipeTransform {
  transform(value: any, skills: Array<any>) {
    const skill = skills.find(skill => skill.id == value)
    return skill.label;
  }
}
