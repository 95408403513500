import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AlertService } from 'src/app/service/alert.service';


@Component({
  selector: 'app-list-comments',
  templateUrl: './list-comments.component.html',
  styleUrls: ['./list-comments.component.scss']
})
export class ListCommentsComponent implements OnInit {

  comments: Array<any> = [] || undefined;

  @Input() postId: any;
  commentsCollection!: AngularFirestoreCollection<any>;
   count = 1;
   @Input() userId: any;
  constructor(
    private firestore: AngularFirestore, 
    private alertService: AlertService
  ) {
    this.alertService.ee.subscribe(res => {
      this.getComments();
      console.log('event fire')
    });

    firestore.collection('comments').valueChanges().subscribe(data => {
      this.getComments();
    });

  

   }

  ngOnInit(): void {
    this.commentsCollection = this.firestore.collection('comments');
 

  }

  ngOnChanges() {
    this.getComments();
  }

  

  getComments() {
    this.firestore.collection('comments').get().toPromise().then((snapshot) => {
      const comments = snapshot?.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id;
        return { id, ...(data as {}) };
      })
      .filter((doc: any) => doc.postId == this.postId).map((doc: any) => {
        return doc;
      })
      this.comments = comments || [];
    })
  }

  removeComment(commentId: any): void {
    this.commentsCollection.doc(commentId).delete();
    this.getComments()
  }

}
