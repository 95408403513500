import { Component, OnInit } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { catchError, map, Observable, of } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { AlertService } from 'src/app/service/alert.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuggestNewSkillComponent } from 'src/app/shared/suggest-new-skill/suggest-new-skill.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BondsService } from 'src/app/service/bonds.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';


//mport { IChatController, User } from 'ng-chat';
import { User, ChatParticipantType, ChatParticipantStatus } from 'ng-chat';

@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss']
})
export class ProfileLayoutComponent implements OnInit {

  public id: any = "";
  public doc!: AngularFirestoreDocument<any>;
  public user!: Observable<any>;
  userdata: any;
  bond: any = {};
  loadingBondButton: boolean = false
  bondStatus: string = '';
  apiLoaded!: Observable<boolean>;


  reportsCollection: AngularFirestoreCollection<any>;
  report: boolean = false;
  reportID: string = '';
  reoprtReason: string = '';
  enableComment: string = '';
  reportForm: UntypedFormGroup;


  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private userService: UserService,
    private alertService: AlertService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private bondsService: BondsService,
    private httpclient: HttpClient,
    private notification: NotificationService,
    private fb: UntypedFormBuilder,
  ) { 
    this.reportsCollection = afs.collection('reports');

    this.reportForm = this.fb.group({
      body: ['', [Validators.required]]
    });

  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      console.log('ddd here')
      this.id = params.get('id');
      this.doc = this.afs.collection('users').doc(this.id);
      this.user = this.doc.valueChanges();
      this.getDataAll();
    });

    

  }

  getDataAll(){

    this.userService.user$.subscribe(data => {
      this.userdata = data;
      this.getBondRequest();
    });

    this.apiLoaded = this.httpclient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.GoogleMapsApi}`, 'callback')
      .pipe(map(() => true), catchError((error) => {
        console.log(error);
        return of(false)
      }));
  }



  convertNumber(value: any) {
    return Number(value)
  }

  getBondRequest() {
    this.bondsService.listBonds(this.userdata.uid).subscribe((snapshot) => {
      let bonds = snapshot.map(e => {
        const data = e.payload.doc.data();
        const id = e.payload.doc.id;
        return { id, ...(data as {}) }
      })
      this.bond = bonds.find((bond: any) => this.id == bond.uid)
    })
  }

  updateBondStatus(bond: any, status: boolean): void {
    const bondIdFirstUser = bond.id;
    const bondIdSecondUser = bond.docId;
    if (status == true) {
      this.bondsService.approveRequest(bondIdFirstUser, bondIdSecondUser, bond.uid).then(() => {
        this.snackBar.open('Congratulations!, Request accepted successfully', 'Got it', {
          duration: 5000
        })
      })
    } else {
      this.removeBondRequest(bond);
    }
  }

  removeBondRequest(bond: any) {
    const bondIdFirstUser = bond.id;
    const bondIdSecondUser = bond.docId;
    this.alertService.confirm('Confirmation!', 'Do you want to remove this User from your Bonds list?').then(res => {
      if (res) {
        this.bondsService.remove(bondIdFirstUser, bondIdSecondUser, bond.uid).then(() => {
          this.snackBar.open('Bond removed successfully', 'Got it', {
            duration: 5000
          });
        })
      }
    })
  }

  sendBondRequest(): void {
    this.alertService.confirm('Confirmation!', ' Do you want to add this User to your Bonds list?').then((res => {
      if (res) {
        this.loadingBondButton = true
        this.bondsService.sendRequest(this.id, 'pending').then(() => {
          this.notification.sendNotification({
            status: 0,
            message: '',
            // title: `${this.userdata.displayName} sent you a bond request`,
            title: ` sent you a bond request`,
            date: new Date(),
            from: this.userdata.uid,
            pageUrl: `/user/${this.id}/view/1`,
            to: [this.id],
            action: true,
            type: 'bondRequest'
          }).then(res => {
            this.snackBar.open('Bond request sent successfully', 'Got it', {
              duration: 5000
            });
            this.loadingBondButton = false
          })
        })
      }
    }))
  }

  // removeBond() {
  //   this.alertService.confirm('Confirmation!', 'Do you want to remove user from your bond list?').then(res => {
  //     if (res) {
  //       this.afs.collection('bonds').doc(this.bond[0].id).delete().then(res => {
  //         this.snackBar.open('Bond removed successfully', 'Got it', {
  //           duration: 5000
  //         });
  //         this.bond = [];
  //       });
  //     }
  //   })
  // }

  openChatWindow() {
    this.user.subscribe(res => {
      console.log(res);

      let chatUser:User = {
        participantType: ChatParticipantType.User,
        id: res.uid,
        displayName: res.displayName,
        avatar: res.photoURL,
        status: ChatParticipantStatus.Online
      }
      this.userService.chatTrigger.next(chatUser as User);
    })
  }

  reportUser() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = false;
    dialogConfig.minWidth = "700px"
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = { reportUser: true }
    this.matDialog.open(SuggestNewSkillComponent, dialogConfig);
  }

  reportFn() {
    if (this.reportForm.valid) {
      const d = new Date();
      this.reportsCollection.add({
        reason: this.reportForm.value.body,
        objectID: this.reportID,
        type: 'USER',
        creator: this.userdata.uid,
        time: Date.now()
      });
      this.report = false;

    }
  }

}
