import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userById'
})

export class UserByIdPipe implements PipeTransform {
  transform(value: any, users: Array<any>) {
    const user = users.find(user => user.uid == value)
    return user.displayName;
  }
}
