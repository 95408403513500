import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/service/project.service';

@Component({
  selector: 'app-public-projects',
  templateUrl: './public-projects.component.html',
  styleUrls: ['./public-projects.component.scss']
})
export class PublicProjectsComponent implements OnInit {

  skills: Array<any> = [];
  displayedColumns: string[] = ['name', 'action'];
  dataSource = new MatTableDataSource<any>();
  loading: boolean = true;
  search: boolean = false;
  searchProjectForm!: UntypedFormGroup;
  skillIds: Array<string> = [];
  searchToggle: boolean = false;
  title: string = '';
  emptyProjects: boolean = false;

  constructor(private afs: AngularFirestore, private fb: UntypedFormBuilder, private route: Router, private projectService: ProjectService) { }

  ngOnInit(): void {
    this.getSkills()
    this.getProjects();

    this.searchProjectForm = this.fb.group({
      title: [''],
      skills: ['']
    })
  }

  getSkills(): void {
    this.afs.collection('skill', ref => ref.orderBy('label')).get().subscribe(snapshot => {
      this.skills = snapshot.docs.map((skill) => {
        return skill.data();
      })
    });
  }

  viewProject(project: any): void {
    this.route.navigate([`project/${project.id}`])
  }

  getProjects(): void {
    this.projectService.publicProjects.then(projects => {
      if (!projects.length) {
        this.emptyProjects = true;
      } else {
        this.emptyProjects = false
      }
      this.dataSource.data = projects
      this.loading = false;
    })
  }

  searchProjects(): void {
    this.searchProjectForm.patchValue({
      skills: this.skillIds
    })
    this.dataSource.data = [];
    this.loading = true;
    const title = this.searchProjectForm.value.title;
    const skills = this.searchProjectForm.value.skills;
    if (!title && !skills.length) {
      this.getProjects()
      return;
    }
    this.projectService.filterProject(title, skills).then(projects => {
      this.emptyProjects = (!projects.length) ? true : false
      this.dataSource.data = projects;
      this.loading = false;
    })
  }

  resetProjects() {
    this.getProjects();
    this.searchProjectForm.reset()
    this.search = false;
  }
}
